import React, {Component} from 'react';
import style from './class-container.css';
import SectionDivider from '../divider.jsx';
import CameraView from '../camera-view/camera-view.jsx';
import {bindAll} from 'lodash';
import ClassMenu from './class-menu/class-menu.jsx';

import cameraOpenIcon from './icons/camera-open-icon.svg';
import editClassNameIcon from './icons/edit-class-name-icon.svg';
import closeIcon from './icons/close-icon.svg';
import settingIcon from './icons/setting-icon.svg';
import dotsIcon from './icons/dots-icon.svg';

import CameraSelector from '../training-result/camera-selector/camera-selector.jsx';
import ImageSample from './image-sample/image-sample.jsx';
import TrainingLine from '../line/training-line.jsx';

class ClassContainer extends Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleOnValueChange',
            'handleChangeClassLabel',
            'handleCameraButtonClick',
            'handleMouseDownRecordButton',
            'handleMouseUpRecordButton',
            'handleMouseLeaveRecordButton',
            'getImageUrl',
            'clear',
            'deleteClass',
            'setclassMenuRef',
            'toggleMirror'
        ]);
        this.state = {
            exampleCount: 0,
            classMenuRef: null,
            exampleUrls: [],
            isMirrored: false
        };

        this.classLabelInput = null;
        this.classLabelSpan = null;

        this.props.tmTrainingInstance.setRenderTrigger(
            this.props.classId,
            exampleCount => {
                this.setState({exampleCount: exampleCount});
            }
        );

        this.classContainerRef = React.createRef();
    }

    componentDidMount () {
        this.classLabelInput = document.getElementById(this.getClassLabelInputId());
        this.classLabelSpan = document.getElementById(this.getClassLabelSpanId());

        this.classLabelInput.addEventListener('change', this.handleChangeClassLabel);
        this.setClassLabelInputAndFakeSpan();

        this.setState({
            exampleCount: this.props.tmTrainingInstance.getExampleCountByClassId(this.props.classId)
        });
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevState.exampleCount < this.state.exampleCount) {
            const from = prevState.exampleCount;
            const to = this.state.exampleCount;
            this.props.onAddNewSample();
            this.addExampleUrl(from, to);
        }
    }

    componentWillUnmount () {
        this.classLabelInput.removeEventListener('change', this.handleChangeClassLabel);
        window.removeEventListener('scroll', this.drawPath);
    }

    addExampleUrl (start, end) {
        const addedExamplesArray = [];
        for (let i = start; i < end; i++) {
            const exampleImgUrl = this.getImageUrl(this.props.tmTrainingInstance.getExample(this.props.classId, i));
            addedExamplesArray.push(exampleImgUrl);
        }
        this.setState({
            exampleUrls: [...addedExamplesArray.reverse(), ...this.state.exampleUrls]
        });
    }

    setClassLabelInputAndFakeSpan () {
        const label = this.props.tmTrainingInstance.getLabelByClassId(this.props.classId);

        this.classLabelInput.value = label;
        this.classLabelSpan.textContent = label;
        this.classLabelInput.style.width = `${this.classLabelSpan.offsetWidth}px`;
    }

    stopRecord(){
        this.props.tmTrainingInstance.stopTraining();
    }

    getClassLabelInputId () {
        return `classLabelInput${this.props.classId}`;
    }

    getClassLabelSpanId () {
        return `classLabelSpan${this.props.classId}`;
    }

    handleChangeClassLabel (event) {
        const newLabel = event.target.value;
        if (!newLabel) {
            this.setClassLabelInputAndFakeSpan();
            return;
        }
        this.props.tmTrainingInstance.setLabel(this.props.classId, newLabel);
        event.target.blur();
    }

    handleCameraButtonClick () {
        this.props.onOpenCameraViewOfClass(this.props.classId);
    }

    handleMouseDownRecordButton () {
        const targetTrainingId = this.props.tmTrainingInstance.getTrainingIdByClassId(this.props.classId);
        this.props.tmTrainingInstance.activateTrainingById(targetTrainingId);
    }

    handleMouseLeaveRecordButton () {
        this.stopRecord();   
    }

    handleMouseUpRecordButton () {
        this.stopRecord();
    }

    handleOnValueChange (event) {
        this.classLabelSpan.textContent = event.target.value;
        const inputWidth = this.classLabelSpan.offsetWidth;
        this.classLabelInput.style.width = `${inputWidth}px`;
    }

    getImageUrl (example){
        if (this.props.tmTrainingInstance.isOldData) {
            const {width: imageWidth, height: imageHeight} = this.props.tmTrainingInstance.getVideoSize();
            const canvas = document.createElement('canvas');
            canvas.width = imageWidth;
            canvas.height = imageHeight;
            const context = canvas.getContext('2d');
            context.putImageData(new ImageData(example, imageWidth, imageHeight), 0, 0);
            return canvas.toDataURL();
        }
        return example;
    }
    deleteSample (exampleIndex) {
        try {
            this.props.onDeleteSample(exampleIndex);

            this.setState({
                exampleCount: this.state.exampleCount - 1,
                exampleUrls: this.state.exampleUrls.filter((__, index) => index !== exampleIndex)
            });
        } catch (e) {
            console.error(e);
        }
    }

    clear () {
        this.props.clear();
        this.setState({
            exampleCount: 0,
            exampleUrls: []
        });
    }

    canClear () {
        return this.state.exampleCount > 0;
    }

    setclassMenuRef (ref) {
        this.setState({classMenuRef: ref});
    }

    deleteClass () {
        this.props.delete();
        this.setClassLabelInputAndFakeSpan();
    }

    toggleMirror () {
        this.setState((prevState) => ({
            isMirrored: !prevState.isMirrored
        }));
        this.props.tmTrainingInstance.setMirrorMode(!this.state.isMirrored);
    }

    render () {
        const {
            tmTrainingInstance,
            video,
            isCameraViewOpened,
            onCloseCameraViewOfClass,
            onClassMenuToggle,
            isClassMenuOpened,
            canDelete
        } = this.props;

        const actualExampleCount = this.state.exampleCount;
        const editClassLabel = () => {
            this.classLabelInput.focus();
        };
        const MAX_LABEL_LENGTH = 50;

        return (
        <div
            className={style.classContainer}
            ref={this.classContainerRef}
        >
            <TrainingLine
                containerRef={this.props.tmModalContainerRef}
                startRefToDrawPath={this.classContainerRef}
                targetRefToDrawPath={this.props.targetRefToDrawPath}
            />
            <div className={style.classLabelSection}>
                <div className={style.classLabelInputWrapper}>
                    <span
                        id={this.getClassLabelSpanId()}
                        className={style.classLabelSpan}
                    />
                    <input
                        id={this.getClassLabelInputId()}
                        className={style.classLabelInput}
                        onChange={this.handleOnValueChange}
                        type="text"
                        maxLength={MAX_LABEL_LENGTH}
                    />
                    <button
                        className={style.editClassLabelButton}
                        onClick={editClassLabel}
                    >
                        <img src={editClassNameIcon} />
                    </button>
                </div>
                <div
                    ref={this.setclassMenuRef}
                    className={style.classMenuToggle}
                    onClick={onClassMenuToggle}
                >
                    {(this.canClear() || canDelete) &&
                        <div className={style.dots}>
                            <img src={dotsIcon} />
                        </div>}
                    {isClassMenuOpened && (
                        <ClassMenu
                            clear={this.clear}
                            delete={this.deleteClass}
                            close={onClassMenuToggle}
                            canClear={this.canClear()}
                            canDelete={canDelete}
                            parentRef={this.state.classMenuRef}
                        />
                    )}
                </div>
            </div>
            <SectionDivider />
            {isCameraViewOpened ? <div className={style.examplesSectionOpened}>
                <div className={style.recordingSection}>
                    <div className={style.spaceBetweenWrapper}>
                        <span className={style.webCamText}>WebCam</span>
                        <button
                            className={style.closeRecordingButton}
                            onClick={onCloseCameraViewOfClass}
                        >
                            <img src={closeIcon} />
                        </button>
                    </div>
                    <CameraSelector
                        onDeviceChange={this.props.onDeviceChange}
                    />
                    <CameraView
                        video={video}
                        isMirrored={this.state.isMirrored}
                        toggleMirror={this.toggleMirror}
                    />
                    <div className={style.recordButtonWrapper}>
                        <button
                            className={style.recordButton}
                            onMouseDown={this.handleMouseDownRecordButton}
                            onMouseUp={this.handleMouseUpRecordButton}
                            onMouseLeave={this.handleMouseLeaveRecordButton}
                        >
                            {`길게 눌러 녹화`}
                        </button>
                        <button className={style.settingRecordButton}>
                            <img src={settingIcon} />
                        </button>
                    </div>
                </div>
                <div className={style.exampleSection}>
                    <div className={style.exampleCountText}>
                        {actualExampleCount ? `${actualExampleCount} 이미지 샘플` : '이미지 샘플 추가 :'}
                    </div>
                    <div className={style.exampleImageWrapperOpened}>
                        {this.state.exampleUrls.map((example, index) =>
                            (<ImageSample
                                key={index}
                                src={example}
                                onDelete={() => this.deleteSample(index)}
                            />)
                        )}
                    </div>
                </div>
            </div> : <div className={style.examplesSectionClosed}>
                <div className={style.exampleCountText}>
                    {actualExampleCount ? `${actualExampleCount} 이미지 샘플` : '이미지 샘플 추가 :'}
                </div>
                <div className={style.buttonAndImagesWrapper}>
                    <button
                        className={style.cameraButton}
                        onClick={this.handleCameraButtonClick}
                    >
                        <img
                            src={cameraOpenIcon}
                            className={style.cameraButtonIcon}
                        />
                        {'웹캠'}
                    </button>
                    <div className={style.exampleImageWrapperClosed}>
                        {this.state.exampleUrls.map((example, index) =>
                            (<ImageSample
                                key={index}
                                src={example}
                                onDelete={() => this.deleteSample(index)}
                            />)
                        )}
                    </div>
                </div>
            </div>
            }
        </div>);
    }
}

export default ClassContainer;
