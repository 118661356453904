import React from 'react';
import style from './training-result.css';
import SectionDivider from '../divider.jsx';
import ConfidenceGraph from './confidence-graph/confidence-graph.jsx';
import CameraView from '../camera-view/camera-view.jsx';
import CameraSelector from './camera-selector/camera-selector.jsx';
import bindAll from 'lodash.bindall';
import CopyUrlButtonIcon from '../copy-url-button.jsx';
import { copyText } from '../../../lib/utils/copyUtil.js';
import classNames from 'classnames';

class TrainingResult extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            prediction: null,
            isMirrored: false
        };

        this.predictTimer = null;
        this.stopPredictAnimate = false;

        bindAll(this, [
            'handleApply',
            'handleSaveSample',
            'handleCopy',
            'toggleMirror'
        ]);
    }

    async predictLoop () {
        const numClasses = this.props.tmTrainingInstance.knn.getNumClasses();

        if (numClasses > 0) {
            await this.props.tmTrainingInstance.predict();
            this.setState({prediction: this.props.tmTrainingInstance.predictionResult});
        }

        if (!this.stopPredictAnimate) {
            this.predictTimer = requestAnimationFrame(this.predictLoop.bind(this));
        }
    }

    componentDidMount () {
        window.requestAnimationFrame(this.predictLoop.bind(this));

        this.resizeObserver = new ResizeObserver(entries => {
            const containerHeight = entries[0].contentRect.height;
            this.props.resultContainerRef.current?.style.setProperty('--container-height', `${containerHeight}px`);
        });
        this.resizeObserver.observe(this.props.resultContainerRef.current);
    }

    componentWillUnmount () {
        this.stopPredictAnimate = true;
        cancelAnimationFrame(this.predictTimer);

        this.resizeObserver.disconnect();
    }

    handleApply () {
        this.props.onApplyModel();
    }
    handleSaveSample () {
        this.props.onSaveSample();
    }

    handleCopy () {
        if (this.props.uploadedSampleLink) {
            copyText(this.props.uploadedSampleLink);
        }
    }

    toggleMirror () {
        this.setState((prevState) => ({
            isMirrored: !prevState.isMirrored
        }));
    }

    render () {
        const {
            video,
            onDeviceChange
        } = this.props;

        const graphColors = [
            {main: '#E27114', background: '#FEEBE2'},
            {main: '#D3466E', background: '#FEE9EC'},
            {main: '#7654EC', background: '#F1F0FF'},
            {main: '#1F6DD0', background: '#D3E4FB'}
        ];
        const predictionResult = this.state.prediction;

        const formatPredictionResult = () => {
            if (!predictionResult) return [];
            const confidences = predictionResult.confidences;

            const results = [];
            Object.entries(confidences).forEach(([id, confidence]) => {
                if (id == -1) {
                    return;
                }
                results.push({
                    id: Number(id),
                    label: this.props.tmTrainingInstance.getLabelByTrainingId(id),
                    value: Math.round(confidence * 100)
                });
            });
            return results;
        };

        const formattedPredictionResult = formatPredictionResult();

        const existingTrainingIds = this.props.tmTrainingInstance.getClassIds().map(id => {
            const trainingId = this.props.tmTrainingInstance.getTrainingIdByClassId(id);
            return trainingId;
        });
        const trainedTrainingIds = formattedPredictionResult.map(c => c.id);

        const notApplicable = JSON.stringify(existingTrainingIds.sort()) !== JSON.stringify(trainedTrainingIds.slice().sort());

        return (<div
            className={classNames(style.sectionContainer, style.trainingResultContainer)}
            ref={this.props.resultContainerRef}
        >
            <div className={style.headerSection}>
                <div className={style.boxTitle}>
                    {'미리보기'}
                </div>
                <button
                    className={style.applyButton}
                    onClick={this.handleSaveSample}
                    disabled={notApplicable || !this.props.isUploadAvailable}
                >
                    {'업로드'}
                </button>
                <button
                    className={style.applyButton}
                    onClick={this.handleApply}
                    disabled={notApplicable || !this.props.isApplyAvailable}
                >
                    {'적용하기'}
                </button>
            </div>
            <div className={style.uploadedUrlContainer}>
                <div className={style.uploadedUrl}>
                    {this.props.uploadedSampleLink ?? '업로드 버튼을 클릭하면 주소가 생성됩니다.'}
                </div>
                <button 
                    className={style.copyButton}
                    onClick={this.handleCopy}
                    disabled={!this.props.uploadedSampleLink}
                >
                    <CopyUrlButtonIcon />
                </button>
            </div>
            <SectionDivider />
            <div className={style.bodySection}>
                <div className={style.inputCamera}>
                    <CameraSelector
                        onDeviceChange={onDeviceChange}
                    />
                    <CameraView
                        video={video}
                        isMirrored={this.state.isMirrored}
                        toggleMirror={this.toggleMirror}
                    />
                </div>
                {predictionResult && <>
                    <SectionDivider /><div className={style.outputPrediction}>
                        <div className={style.subTitle}>
                            {'출력'}
                        </div>
                        <div className={style.predictionResult}>
                            {formattedPredictionResult.map((confidence, index) =>
                                (<ConfidenceGraph
                                    key={confidence.id}
                                    confidence={confidence}
                                    graphColor={graphColors[index % graphColors.length]}
                                />)
                            )}
                        </div>
                    </div>
                    </>}
            </div>
        </div>);
    }
}

export default TrainingResult;
