import React from 'react';
import PropTypes from 'prop-types';
import CustomInputPopupComponent from '../components/custom-input-popup/custom-input-popup.jsx';
import VM from 'scratch-vm';
import bindAll from 'lodash.bindall';

export const MONITOR_NAMES = ['malrang_monitor', 'technic_monitor', 'newmalrang_monitor'];
class CustomInputPopup extends React.Component {
    constructor (props) {
        super(props);

        bindAll(this, [
            'splitMonitors',
            'setTopMonitor',
        ]);

        this.state = {
            topMonitor: null,
        };
    }

    splitMonitors (monitors) {
        const splitMonitor = {};
        
        MONITOR_NAMES.forEach(monitorName => {
            splitMonitor[monitorName] = monitors.filter(
                m => m.id.startsWith(monitorName)
            );
        }
        );

        return splitMonitor;
    }

    setTopMonitor (monitorKey) {
        this.setState({ topMonitor: monitorKey });
    }

    render () {
        const monitors = this.props.vm.runtime.getMonitorState();

        const splitedMonitors = this.splitMonitors(monitors);

        return (
            Object.keys(splitedMonitors).map(splitMonitorKey => {
                return (
                    splitedMonitors[splitMonitorKey].size ?
                        <CustomInputPopupComponent
                            monitors={splitedMonitors[splitMonitorKey]}
                            vm={this.props.vm}
                            splitMonitorKey={splitMonitorKey}
                            topMonitor={this.state.topMonitor}
                            onClickMonitor={this.setTopMonitor}
                        /> : null
                )
            })
        );
    }
}

CustomInputPopup.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired
};

export default CustomInputPopup;
