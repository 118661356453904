exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".line_line_3Y9Tt {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 0;\n    overflow: visible;\n    pointer-events: none;\n}\n\n.line_line_3Y9Tt > path {\n    fill: none;\n    stroke: #C1C4C9;\n    stroke-width: 3;\n}", ""]);

// exports
exports.locals = {
	"line": "line_line_3Y9Tt"
};