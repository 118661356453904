import {useEffect} from 'react';
import React from 'react';
import removeAllChild from '../../../lib/utils/removeAllChild';

const BLOCK_SCALE_MULTIPLIER = 0.7;

/** @typedef {CheckboxOptions} BlockStacksProps */
const checkboxOptions = {
    dx: -33,
    dy: 10
}

const useBlockStacks = (
    blockSvgGroups,
    blockStacksListRef,
    onClickHideBlockStackCheckbox,
    hiddenBlockIdsInWorkspace
) => {
    useEffect(() => {
        if (blockStacksListRef.current === null) return;
        if (blockSvgGroups.length === 0) return;

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('width', '100%');
        blockStacksListRef.current.appendChild(svg);
        let currentHeight = 0;
        blockSvgGroups.forEach(block => {
            const dataType = block.getAttribute('data-type');
            const clonedBlock = block.cloneNode(true);
            const blockId = block.getAttribute('data-id');
            const isDataBlock = dataType === 'data_listcontents' || dataType === 'data_variable';
            const blockIdentifier = isDataBlock ? clonedBlock.getElementsByTagName('text')[0].textContent : blockId;

            clonedBlock.setAttribute('transform', `scale(${BLOCK_SCALE_MULTIPLIER}), translate(50, ${currentHeight})`);
            svg.appendChild(clonedBlock);

            const { height } = clonedBlock.getBBox();

            const checkbox = createCheckBox(
                (event, checked) => {
                    onClickHideBlockStackCheckbox(blockIdentifier, checked);
                },
                !!hiddenBlockIdsInWorkspace.get(blockIdentifier),
                checkboxOptions
            );
            clonedBlock.appendChild(checkbox);

            currentHeight += height + 30;
        });
        svg.setAttribute('height', currentHeight * BLOCK_SCALE_MULTIPLIER);

        return () => {
            blockStacksListRef.current.removeChild(svg);
        }
    }, [blockSvgGroups]);
}

/**
 * options for creating a checkbox
 * @typedef {Object} CheckboxOptions
 * @property {number} dx - pixels that the checkbox should be moved to the horizontal coordinate
 * @property {number} dy - pixels that the checkbox should be moved to the vertical coordinate
 */

/**
 * 
 * @param {(event: PointerEvent, checked: boolean) => void} onClick click event handler
 * @param {boolean} checked boolean value that indicates whether the checkbox is checked
 * @param {CheckboxOptions} options 
 * @returns 
 */
const createCheckBox = (onClick, checked, options) => {
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const { dx, dy } = options;

    prepareCheckBoxAttribute(group, rect, path, dx, dy);
    drawCheckBox(checked, group, rect, path);

    group.addEventListener('click', (event) => {
        checked = !checked;
        drawCheckBox(checked, group, rect, path);
        onClick(event, checked);
    });

    return group;
}

const prepareCheckBoxAttribute = (group, rect, path, dx, dy) => {
    rect.setAttribute('width', '20');
    rect.setAttribute('height', '20');

    path.setAttribute('d', 'M4 9L7 14L16 5');
    path.setAttribute('stroke', 'white');
    path.setAttribute('stroke-width', '2');
    path.setAttribute('stroke-linecap', 'round');
    path.setAttribute('fill', 'none');

    group.setAttribute('transform', `scale(1.3), translate(${dx}, ${dy})`);
    group.setAttribute('cursor', 'pointer');
}

const drawCheckBox = (checked, group, rect, path) => {
    removeAllChild(group);

    rect.setAttribute('fill', checked ? '#1F6DD0' : 'white');
    rect.setAttribute('stroke', checked ? '' : '#999');
    rect.setAttribute('stroke-width', checked ? '' : '2');
    rect.setAttribute('rx', checked ? '4' : '3');

    group.appendChild(rect);

    if (checked) {
        group.appendChild(path);
    }
}

export default useBlockStacks;