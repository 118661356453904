import bindAll from 'lodash.bindall';
import debounce from 'lodash.debounce';
import defaultsDeep from 'lodash.defaultsdeep';
import makeToolboxXML from '../lib/make-toolbox-xml';
import PropTypes from 'prop-types';
import React from 'react';
import VMScratchBlocks from '../lib/blocks';
import VM from 'scratch-vm';

import log from '../lib/log.js';
import Prompt from './prompt.jsx';
import BlocksComponent from '../components/blocks/blocks.jsx';
import ExtensionLibrary from './extension-library.jsx';
import extensionData from '../lib/libraries/extensions/index.jsx';
import CustomProcedures from './custom-procedures.jsx';
import errorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {BLOCKS_DEFAULT_SCALE, STAGE_DISPLAY_SIZES} from '../lib/layout-constants';
import DropAreaHOC from '../lib/drop-area-hoc.jsx';
import DragConstants from '../lib/drag-constants';
import defineDynamicBlock from '../lib/define-dynamic-block';

import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {updateToolbox, isToolboxEditable} from '../reducers/toolbox';
import {activateColorPicker} from '../reducers/color-picker';
import {closeExtensionLibrary, openSoundRecorder, openConnectionModal, openMecanumWheelModal, openTMTrainingModal} from '../reducers/modals';
import {activateCustomProcedures, deactivateCustomProcedures} from '../reducers/custom-procedures';
import {setVisibleTMModal, setSelectedCamera, setAvailableVideo} from '../reducers/custom-TM-modal';
import { setDroneMode, setSimulatorMode } from '../reducers/mode.js';
import {setConnectionModalExtensionId, setUpdateFirmware} from '../reducers/connection-modal';
import {setAnyBlockUsedInExtension, setAnyPeripheralConnected, setPositionYofAlert} from '../reducers/remove-extension-state.js';
import {updateMetrics} from '../reducers/workspace-metrics';

import {
    activateTab,
    SOUNDS_TAB_INDEX
} from '../reducers/editor-tab';
import CustomTeachableMachine from './custom-teachable-machine.jsx';
import CodingPlayButton from './coding-play-button.jsx';
import {setAutoCloseChangeHandler} from '../reducers/auto-close.js';

import { setWorkspace } from '../reducers/workspace.js';
import { DefaultBlockCategories } from '../lib/constant/blocks.js';
import { makeHideBlockCheckboxes } from '../lib/utils/malrangHideBlock.js';
import { setScratchBlocks } from '../reducers/scratch-blocks.js';
import { setUpdateHex } from '../reducers/connection-modal.js';

const IS_IN_SEMOBAE = !!window.semobaeAPI;

const addFunctionListener = (object, property, callback) => {
    const oldFn = object[property];
    object[property] = function (...args) {
        const result = oldFn.apply(this, args);
        callback.apply(this, result);
        return result;
    };
};

const DroppableBlocks = DropAreaHOC([
    DragConstants.BACKPACK_CODE
])(BlocksComponent);

const messages = defineMessages({
    droneMode: {
        defaultMessage: 'drone mode',
        description: 'drone Mode',
        id: 'gui.byrobotDrone.setDroneMode'
    },
    simulatorMode: {
        defaultMessage: 'simulator mode',
        description: 'simulator mode',
        id: 'gui.byrobotDrone.setSimulatorMode'
    },
    droneModeUsing: {
        defaultMessage: 'drone mode (applied)',
        description: 'drone mode (applied)',
        id: 'gui.byrobotDrone.droneModeUsing'
    },
    simulatorModeUsing: {
        defaultMessage: 'simulator mode (applied)',
        description: 'simulator mode (applied)',
        id: 'gui.byrobotDrone.simulatorModeUsing'
    }
});

class Blocks extends React.Component {
    constructor (props) {
        super(props);
        this.ScratchBlocks = VMScratchBlocks(props.vm);
        bindAll(this, [
            'attachVM',
            'detachVM',
            'getToolboxXML',
            'handleCategorySelected',
            'handleConnectionModalStart',
            'handleDrop',
            'handleStatusButtonUpdate',
            'handleDevicePairingUpdate',
            'handleOpenSoundRecorder',
            'handlePromptStart',
            'handlePromptCallback',
            'handlePromptClose',
            'handleCustomProceduresClose',
            'handleTeachableMachineStart',
            'startTrainingTMModel',
            'handleTeachableMachineClose',
            'startMcPIP',
            'setDroneMode',
            'setSimulatorMode',
            'onScriptGlowOn',
            'onScriptGlowOff',
            'onBlockGlowOn',
            'onBlockGlowOff',
            'handleMonitorsUpdate',
            'handleExtensionAdded',
            'handleBlocksInfoUpdate',
            'onTargetsUpdate',
            'onVisualReport',
            'onWorkspaceUpdate',
            'onWorkspaceMetricsChange',
            'setBlocks',
            'setLocale',
            'removeExtension',
            'handleFlyoutVisibilityChangeIfAutoCloseEnabled',
            'setSemobaeExtensionBlocks',
            'disableDroneModeButton',
            'disableSimulatorModeButton',
            'setDroneModeGlobalState',
            'setSimulatorModeGlobalState',
            'handleDisableSimulator',
        ]);
        this.ScratchBlocks.prompt = this.handlePromptStart;
        this.ScratchBlocks.statusButtonCallback = this.handleConnectionModalStart;
        this.ScratchBlocks.recordSoundCallback = this.handleOpenSoundRecorder;

        this.state = {
            prompt: null,
            customTeachableMachineVisible: null,
            codingButtonVisible: false,
            flyoutVisibility: true
        };
        this.onTargetsUpdate = debounce(this.onTargetsUpdate, 100);
        this.toolboxUpdateQueue = [];
        this.props.updateScratchBlocks(this.ScratchBlocks);
    }
    componentDidMount () {
        this.ScratchBlocks.FieldColourSlider.activateEyedropper_ = this.props.onActivateColorPicker;
        this.ScratchBlocks.Procedures.externalProcedureDefCallback = this.props.onActivateCustomProcedures;
        this.ScratchBlocks.ScratchMsgs.setLocale(this.props.locale);

        const workspaceConfig = defaultsDeep({},
            Blocks.defaultOptions,
            this.props.options,
            {rtl: this.props.isRtl, toolbox: this.props.toolboxXML}
        );
        this.workspace = this.ScratchBlocks.inject(this.blocks, workspaceConfig);
        this.props.updateWorkspace(this.workspace);

        // Register buttons under new callback keys for creating variables,
        // lists, and procedures from extensions.

        const toolboxWorkspace = this.workspace.getFlyout().getWorkspace();

        const varListButtonCallback = type =>
            (() => this.ScratchBlocks.Variables.createVariable(this.workspace, null, type));
        const procButtonCallback = () => {
            this.ScratchBlocks.Procedures.createProcedureDefCallback_(this.workspace);
        };
        const teachableMachineButtonCallback = () => {
            this.handleTeachableMachineStart();
        };

        const startMcPIP = () => {
            this.startMcPIP();
        };

        toolboxWorkspace.registerButtonCallback('MAKE_A_VARIABLE', varListButtonCallback(''));
        toolboxWorkspace.registerButtonCallback('MAKE_A_LIST', varListButtonCallback('list'));
        toolboxWorkspace.registerButtonCallback('MAKE_A_PROCEDURE', procButtonCallback);
        toolboxWorkspace.registerButtonCallback('MAKE_A_TM_MODEL', teachableMachineButtonCallback);
        toolboxWorkspace.registerButtonCallback('MAKE_AN_OFFLINE_TM_MODEL', this.startTrainingTMModel);
        toolboxWorkspace.registerButtonCallback('START_MC_PIP', startMcPIP);
        toolboxWorkspace.registerButtonCallback('SET_MACANUMWHEEL', () => this.startSettingMecanumwheel());
        toolboxWorkspace.registerButtonCallback('UPDATE_NEMO_FIRMWARE', () => this.startUpdateFirmware('nemo'));
        toolboxWorkspace.registerButtonCallback('UPDATE_NEWMALRANG_FIRMWARE', () => this.startUpdateFirmware('newmalrang'));
        toolboxWorkspace.registerButtonCallback('SET_DRONE_MODE', () => this.setDroneMode());
        toolboxWorkspace.registerButtonCallback('SET_SIMULATOR_MODE', () => this.setSimulatorMode());
        // Store the xml of the toolbox that is actually rendered.
        // This is used in componentDidUpdate instead of prevProps, because
        // the xml can change while e.g. on the costumes tab.
        this._renderedToolboxXML = this.props.toolboxXML;

        // we actually never want the workspace to enable "refresh toolbox" - this basically re-renders the
        // entire toolbox every time we reset the workspace.  We call updateToolbox as a part of
        // componentDidUpdate so the toolbox will still correctly be updated
        this.setToolboxRefreshEnabled = this.workspace.setToolboxRefreshEnabled.bind(this.workspace);
        this.workspace.setToolboxRefreshEnabled = () => {
            this.setToolboxRefreshEnabled(false);
        };

        // @todo change this when blockly supports UI events
        addFunctionListener(this.workspace, 'translate', this.onWorkspaceMetricsChange);
        addFunctionListener(this.workspace, 'zoom', this.onWorkspaceMetricsChange);

        this.attachVM();
        // Only update blocks/vm locale when visible to avoid sizing issues
        // If locale changes while not visible it will get handled in didUpdate
        if (this.props.isVisible) {
            this.setLocale();
        }

        const flyout = this.workspace.getFlyout();
        flyout.visibilityChangeListener_ = this.handleFlyoutVisibilityChangeIfAutoCloseEnabled;
        this.props.updateAutoCloseChangeHandler(() => {
            flyout.setAutoClose(this.props.autoClose);
            if (!this.props.autoClose && !flyout.isVisible()) {
                flyout.setVisible(true);
            }
        });

        try {
            for (const extensionId of build.env.PRELOADING_EXTENSIONS) {
                if (!extensionData.some(extension => extension.extensionId === extensionId)) {
                    console.warn(`Extension ${extensionId} is not in the extension whitelist.`);
                    continue;
                }
                if (!this.props.vm.extensionManager.isExtensionLoaded(extensionId)) {
                    this.props.vm.extensionManager.loadExtensionIdSync(extensionId);
                }
            }
        } catch (e) {
            console.warn('build.env.PRELOADING_EXTENSIONS may not be defined.', e.message);
        }
    }
    shouldComponentUpdate (nextProps, nextState) {
        return (
            this.state.prompt !== nextState.prompt ||
            this.state.customTeachableMachineVisible !== nextState.customTeachableMachineVisible ||
            this.state.codingButtonVisible !== nextState.codingButtonVisible ||
            this.props.isVisible !== nextProps.isVisible ||
            this._renderedToolboxXML !== nextProps.toolboxXML ||
            this.props.extensionLibraryVisible !== nextProps.extensionLibraryVisible ||
            this.props.customProceduresVisible !== nextProps.customProceduresVisible ||
            this.props.locale !== nextProps.locale ||
            this.props.anyModalVisible !== nextProps.anyModalVisible ||
            this.props.stageSize !== nextProps.stageSize ||
            this.props.customTMModalVisible !== nextProps.customTMModalVisible ||
            this.props.tmSelectedCamera !== nextProps.tmSelectedCamera ||
            this.props.tmIsAvailableVideo !== nextProps.tmIsAvailableVideo ||
            this.props.isToolboxEditable !== nextProps.isToolboxEditable
        );
    }
    componentDidUpdate (prevProps) {
        if (this.props.isToolboxEditable !== prevProps.isToolboxEditable) {
            this.toggleEditToolbox();
        }

        // If any modals are open, call hideChaff to close z-indexed field editors
        if (this.props.anyModalVisible && !prevProps.anyModalVisible) {
            this.ScratchBlocks.hideChaff();
        }

        // Only rerender the toolbox when the blocks are visible and the xml is
        // different from the previously rendered toolbox xml.
        // Do not check against prevProps.toolboxXML because that may not have been rendered.
        if (this.props.isVisible && this.props.toolboxXML !== this._renderedToolboxXML) {
            this.requestToolboxUpdate();
        }

        // Pocl. force update toolbox when is malrangtutorial
        if (
            this.props.isMissionMode &&
            (this.props.toolboxXML !== prevProps.toolboxXML)
        ) {
            this.requestToolboxUpdate();
        }

        if (this.props.isVisible === prevProps.isVisible) {
            if (this.props.stageSize !== prevProps.stageSize) {
                // force workspace to redraw for the new stage size
                window.dispatchEvent(new Event('resize'));
            }
            return;
        }
        // @todo hack to resize blockly manually in case resize happened while hidden
        // @todo hack to reload the workspace due to gui bug #413
        if (this.props.isVisible) { // Scripts tab
            this.workspace.setVisible(true);
            if (prevProps.locale !== this.props.locale || this.props.locale !== this.props.vm.getLocale()) {
                // call setLocale if the locale has changed, or changed while the blocks were hidden.
                // vm.getLocale() will be out of sync if locale was changed while not visible
                this.setLocale();
            } else {
                this.props.vm.refreshWorkspace();
                this.requestToolboxUpdate();
            }

            window.dispatchEvent(new Event('resize'));
        } else {
            this.workspace.setVisible(false);
        }
    }
    componentWillUnmount () {
        this.detachVM();
        this.workspace.dispose();
        clearTimeout(this.toolboxUpdateTimeout);
    }
    requestToolboxUpdate (toolboxXML) {
        clearTimeout(this.toolboxUpdateTimeout);
        this.toolboxUpdateTimeout = setTimeout(() => {
            this.updateToolbox(toolboxXML);
        }, 0);
    }
    setLocale () {
        this.ScratchBlocks.ScratchMsgs.setLocale(this.props.locale);
        this.props.vm.setLocale(this.props.locale, this.props.messages)
            .then(() => {
                this.workspace.getFlyout().setRecyclingEnabled(false);
                this.props.vm.refreshWorkspace();
                this.requestToolboxUpdate();
                this.withToolboxUpdates(() => {
                    this.workspace.getFlyout().setRecyclingEnabled(true);
                });
            });
    }

    updateToolbox (toolboxXML) {
        this.toolboxUpdateTimeout = false;

        toolboxXML ||= this.props.toolboxXML;
        let categoryId = '';
        let offset = 0;
        const hasSelectedCategory = this.workspace.toolbox_.getSelectedItem();
        if (hasSelectedCategory) {
            categoryId = this.workspace.toolbox_.getSelectedCategoryId();
            offset = this.workspace.toolbox_.getCategoryScrollOffset();
        }
        this.workspace.updateToolbox(toolboxXML);
        this._renderedToolboxXML = toolboxXML;

        // In order to catch any changes that mutate the toolbox during "normal runtime"
        // (variable changes/etc), re-enable toolbox refresh.
        // Using the setter function will rerender the entire toolbox which we just rendered.
        this.workspace.toolboxRefreshEnabled_ = true;

        if (hasSelectedCategory) {
            const currentCategoryPos = this.workspace.toolbox_.getCategoryPositionById(categoryId);
            const currentCategoryLen = this.workspace.toolbox_.getCategoryLengthById(categoryId);
            if (offset < currentCategoryLen) {
                this.workspace.toolbox_.setFlyoutScrollPos(currentCategoryPos + offset);
            } else {
                this.workspace.toolbox_.setFlyoutScrollPos(currentCategoryPos);
            }
        }

        const queue = this.toolboxUpdateQueue;
        this.toolboxUpdateQueue = [];
        queue.forEach(fn => fn());

        // if in malrang modal and show all block categories is false, make checkboxes
        if (this.props.vm.runtime.openMalrangModal && !this.props.showAllBlockCategories) {
            makeHideBlockCheckboxes(this.props.vm);
        }
        if (this.props.vm.getPeripheralIsConnected('codingRider')) {
            if (this.props.isSimulatorMode) {
                this.disableSimulatorModeButton();
                return;
            }
            this.disableDroneModeButton();
        }
    }

    withToolboxUpdates (fn) {
        // if there is a queued toolbox update, we need to wait
        if (this.toolboxUpdateTimeout) {
            this.toolboxUpdateQueue.push(fn);
        } else {
            fn();
        }
    }

    attachVM () {
        this.workspace.addChangeListener(this.props.vm.blockListener);
        this.workspace.addChangeListener(this.removeExtension);
        this.flyoutWorkspace = this.workspace
            .getFlyout()
            .getWorkspace();
        this.flyoutWorkspace.addChangeListener(this.props.vm.flyoutBlockListener);
        this.flyoutWorkspace.addChangeListener(this.props.vm.monitorBlockListener);
        this.props.vm.addListener('SCRIPT_GLOW_ON', this.onScriptGlowOn);
        this.props.vm.addListener('SCRIPT_GLOW_OFF', this.onScriptGlowOff);
        this.props.vm.addListener('BLOCK_GLOW_ON', this.onBlockGlowOn);
        this.props.vm.addListener('BLOCK_GLOW_OFF', this.onBlockGlowOff);
        this.props.vm.addListener('VISUAL_REPORT', this.onVisualReport);
        this.props.vm.addListener('workspaceUpdate', this.onWorkspaceUpdate);
        this.props.vm.addListener('targetsUpdate', this.onTargetsUpdate);
        this.props.vm.addListener('MONITORS_UPDATE', this.handleMonitorsUpdate);
        this.props.vm.addListener('EXTENSION_ADDED', this.handleExtensionAdded);
        this.props.vm.addListener('BLOCKSINFO_UPDATE', this.handleBlocksInfoUpdate);
        this.props.vm.addListener('PERIPHERAL_CONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.addListener('PERIPHERAL_DISCONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.addListener('PERIPHERAL_DISCONNECTED', this.handleDisableSimulator);
        this.props.vm.addListener('DEVICE_PAIRED', this.handleDevicePairingUpdate);
        this.props.vm.addListener('DEVICE_UNPAIRED', this.handleDevicePairingUpdate);
        this.props.vm.addListener('DRONE_MODE_DEFAULT', this.disableDroneModeButton);
        this.props.vm.addListener('DRONE_MODE_SIMULATOR', this.disableSimulatorModeButton);
        this.props.vm.addListener('DRONE_MODE_DEFAULT', this.setDroneModeGlobalState);
        this.props.vm.addListener('DRONE_MODE_SIMULATOR', this.setSimulatorModeGlobalState);
    }
    detachVM () {
        this.props.vm.removeListener('SCRIPT_GLOW_ON', this.onScriptGlowOn);
        this.props.vm.removeListener('SCRIPT_GLOW_OFF', this.onScriptGlowOff);
        this.props.vm.removeListener('BLOCK_GLOW_ON', this.onBlockGlowOn);
        this.props.vm.removeListener('BLOCK_GLOW_OFF', this.onBlockGlowOff);
        this.props.vm.removeListener('VISUAL_REPORT', this.onVisualReport);
        this.props.vm.removeListener('workspaceUpdate', this.onWorkspaceUpdate);
        this.props.vm.removeListener('targetsUpdate', this.onTargetsUpdate);
        this.props.vm.removeListener('MONITORS_UPDATE', this.handleMonitorsUpdate);
        this.props.vm.removeListener('EXTENSION_ADDED', this.handleExtensionAdded);
        this.props.vm.removeListener('BLOCKSINFO_UPDATE', this.handleBlocksInfoUpdate);
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.handleDisableSimulator);
        this.props.vm.removeListener('DEVICE_PAIRED', this.handleDevicePairingUpdate);
        this.props.vm.removeListener('DEVICE_UNPAIRED', this.handleDevicePairingUpdate);
        this.props.vm.removeListener('DRONE_MODE_DEFAULT', this.disableDroneModeButton);
        this.props.vm.removeListener('DRONE_MODE_SIMULATOR', this.disableSimulatorModeButton);
        this.props.vm.removeListener('DRONE_MODE_DEFAULT', this.setDroneModeGlobalState);
        this.props.vm.removeListener('DRONE_MODE_SIMULATOR', this.setSimulatorModeGlobalState);
    }

    setDroneModeGlobalState () {
        this.props.setDroneMode();
    }

    setSimulatorModeGlobalState () {
        this.props.setSimulatorMode();
    }

    disableDroneModeButton () {
        const droneModeButton = document.querySelector('g[data-id="SET_DRONE_MODE"]');
        const simulatorModeButton = document.querySelector('g[data-id="SET_SIMULATOR_MODE"]');
        if (!droneModeButton || !simulatorModeButton) {
            log.error('Drone mode button or simulator mode button is not found');
            return;
        }

        const droneModeButtonText = droneModeButton.querySelector('text');
        const simulatorModeButtonText = simulatorModeButton.querySelector('text');
        if (!droneModeButtonText || !simulatorModeButtonText) {
            log.error('Drone mode button text or simulator mode button text is not found');
            return;
        }
        droneModeButtonText.textContent = this.props.intl.formatMessage(messages.droneModeUsing);
        simulatorModeButtonText.textContent = this.props.intl.formatMessage(messages.simulatorMode);
        droneModeButton.classList.add('disable-hover');
        simulatorModeButton.classList.remove('disable-hover');
    }

    disableSimulatorModeButton () {
        const droneModeButton = document.querySelector('g[data-id="SET_DRONE_MODE"]');
        const simulatorModeButton = document.querySelector('g[data-id="SET_SIMULATOR_MODE"]');
        if (!droneModeButton || !simulatorModeButton) {
            log.error('Drone mode button or simulator mode button is not found');
            return;
        }
        const droneModeButtonText = droneModeButton.querySelector('text');
        const simulatorModeButtonText = simulatorModeButton.querySelector('text');
        if (!droneModeButtonText || !simulatorModeButtonText) {
            log.error('Drone mode button text or simulator mode button text is not found');
            return;
        }
        droneModeButtonText.textContent = this.props.intl.formatMessage(messages.droneMode);
        simulatorModeButtonText.textContent = this.props.intl.formatMessage(messages.simulatorModeUsing);
        droneModeButton.classList.remove('disable-hover');
        simulatorModeButton.classList.add('disable-hover');
    }

    isAnyBlockUsedInExtension (extensionId) {
        const allBlocks = this.workspace.getAllBlocks();
        return Object.values(allBlocks).some(block => block.type.includes(extensionId));
    }

    removeExtension (e){
        if (e.element === 'remove-extension') {
            this.props.updateAnyBlockUsedInExtension(false);
            this.props.updateAnyPeripheralConnected(false);

            const extensionId = e.oldValue.id;
            const extensionPosition = e.oldValue.positionY;

            if (this.isAnyBlockUsedInExtension(extensionId)) {
                this.props.updateAnyBlockUsedInExtension(true);
                this.props.updateAlertPosition(extensionPosition);
                return;
            }
            if (this.props.vm.getPeripheralIsConnected(extensionId)) {
                this.props.updateAnyPeripheralConnected(true);
                this.props.updateAlertPosition(extensionPosition);
                return;
            }

            this.workspace.toolbox_.removeCategoryById(extensionId);
            this.props.vm.removeExtension(extensionId);
            this.requestToolboxUpdate();
        }
    }

    #mapSemoExtensionToScratchExtension (extensionId) {
        switch (extensionId) {
            case '@semobae-ex/malrang-link':
                return 'malrang';
            case '@semobae-ex/nemo-link':
                return 'nemo';
            case '@semobae-ex/probo-technic-link':
                return 'technic';
            case '@semobae-ex/mc-link':
                return 'markCoding';
            case '@semobae-ex/coding-drone-link':
                return 'codingDrone';
            case '@semobae-ex/byrobot-drone-link':
                return 'codingRider';
            case '@semobae-ex/skykick-link':
                return 'skykick';
            default:
                return null;
        }
    }

    /**
     * POCL.
     * Load Scratch extension blocks for activated SemoBae links.
     * Not used due to https://pocl.atlassian.net/browse/POCL-14702
     *
     * @returns {void}
     */
    setSemobaeExtensionBlocks () {
        this.props.semoclassExtensions.map(extensionId => {
            return this.#mapSemoExtensionToScratchExtension(extensionId);
        }).filter(extension => {
            return extension;
        }).forEach(extension => {
            if (!this.props.vm.extensionManager.isExtensionLoaded(extension)) {
                this.props.vm.extensionManager.loadExtensionURL(extension);
            }
        });
    }

    toggleEditToolbox () {
        this.workspace.toolbox_.setEditable(this.props.isToolboxEditable);
        this.requestToolboxUpdate();
    }

    updateToolboxBlockValue (id, value) {
        this.withToolboxUpdates(() => {
            const block = this.workspace
                .getFlyout()
                .getWorkspace()
                .getBlockById(id);
            if (block) {
                block.inputList[0].fieldRow[0].setValue(value);
            }
        });
    }

    onTargetsUpdate () {
        if (this.props.vm.editingTarget && this.workspace.getFlyout()) {
            ['glide', 'move', 'set'].forEach(prefix => {
                this.updateToolboxBlockValue(`${prefix}x`, Math.round(this.props.vm.editingTarget.x).toString());
                this.updateToolboxBlockValue(`${prefix}y`, Math.round(this.props.vm.editingTarget.y).toString());
            });
        }
    }
    onWorkspaceMetricsChange () {
        const target = this.props.vm.editingTarget;
        if (target && target.id) {
            // Dispatch updateMetrics later, since onWorkspaceMetricsChange may be (very indirectly)
            // called from a reducer, i.e. when you create a custom procedure.
            // TODO: Is this a vehement hack?
            setTimeout(() => {
                this.props.updateMetrics({
                    targetID: target.id,
                    scrollX: this.workspace.scrollX,
                    scrollY: this.workspace.scrollY,
                    scale: this.workspace.scale
                });
            }, 0);
        }
    }
    onScriptGlowOn (data) {
        this.workspace.glowStack(data.id, true);
    }
    onScriptGlowOff (data) {
        this.workspace.glowStack(data.id, false);
    }
    onBlockGlowOn (data) {
        this.workspace.glowBlock(data.id, true);
    }
    onBlockGlowOff (data) {
        this.workspace.glowBlock(data.id, false);
    }
    onVisualReport (data) {
        this.workspace.reportValue(data.id, data.value);
    }
    getToolboxXML () {
        // Use try/catch because this requires digging pretty deep into the VM
        // Code inside intentionally ignores several error situations (no stage, etc.)
        // Because they would get caught by this try/catch
        try {
            let {editingTarget: target, runtime} = this.props.vm;
            const stage = runtime.getTargetForStage();
            if (!target) target = stage; // If no editingTarget, use the stage

            const stageCostumes = stage.getCostumes();
            const targetCostumes = target.getCostumes();
            const targetSounds = target.getSounds();
            const dynamicBlocksXML = this.props.vm.runtime.getBlocksXML(target);
            return makeToolboxXML(false, target.isStage, target.id, dynamicBlocksXML,
                targetCostumes[targetCostumes.length - 1].name,
                stageCostumes[stageCostumes.length - 1].name,
                targetSounds.length > 0 ? targetSounds[targetSounds.length - 1].name : '',
                this.props.vm, (this.props.showAllBlockCategories && !this.props.isMissionMode)
            );
        } catch {
            return null;
        }
    }
    onWorkspaceUpdate (data, forceUpdate) {
        if (
            (this.props.isMissionMode || this.props.vm.runtime.openMalrangModal) &&
            this.props.vm.runtime.filterBlockCategories
        ) {
            const alreadyLoadedExtensionsId = Array.from(
                this.props.vm.extensionManager._loadedExtensions.keys()
            );

            const visibleBlocks = this.props.vm.runtime.openMalrangModal ? this.props.vm.runtime.allVisibleBlocks : this.props.vm.runtime.visibleBlocks;

            // remove extensions that are not in workspace
            alreadyLoadedExtensionsId
                .filter((extensionId) => {
                    return !visibleBlocks[extensionId];
                })
                .forEach((alreadyLoadedExtension) => {
                    this.props.vm.extensionManager.removeExtension(alreadyLoadedExtension);
                    this.props.vm.runtime.removeExtension(alreadyLoadedExtension);
                });

            // load extensions that are in runtime.visibleBlocks but not in loadedExtensions
            Object.keys(visibleBlocks)
                .filter((category) => {
                    return !DefaultBlockCategories.includes(category);
                })
                .forEach((extension) => {
                    if (this.props.vm.extensionManager.isExtensionLoaded(extension)) {
                        return;
                    }
                    this.props.vm.extensionManager.loadExtensionURL(extension);
                });
            this.props.vm.extensionManager.refreshBlocks()
        }

        // When we change sprites, update the toolbox to have the new sprite's blocks
        const toolboxXML = this.getToolboxXML();
        if (toolboxXML) {
            this.props.updateToolboxState(toolboxXML);
        }

        if (forceUpdate) {
            this.requestToolboxUpdate(toolboxXML);
        }

        if (this.props.vm.editingTarget && !this.props.workspaceMetrics.targets[this.props.vm.editingTarget.id]) {
            this.onWorkspaceMetricsChange();
        }

        // Remove and reattach the workspace listener (but allow flyout events)
        this.workspace.removeChangeListener(this.props.vm.blockListener);
        const dom = this.ScratchBlocks.Xml.textToDom(data.xml);
        try {
            // 미션 모드 관련 Warning: https://pocl.atlassian.net/wiki/spaces/ALUX/pages/2693202002/missionMode+clearWorkspaceAndLoadFromXml
            this.ScratchBlocks.Xml.clearWorkspaceAndLoadFromXml(dom, this.workspace);
        } catch (error) {
            // The workspace is likely incomplete. What did update should be
            // functional.
            //
            // Instead of throwing the error, by logging it and continuing as
            // normal lets the other workspace update processes complete in the
            // gui and vm, which lets the vm run even if the workspace is
            // incomplete. Throwing the error would keep things like setting the
            // correct editing target from happening which can interfere with
            // some blocks and processes in the vm.
            if (error.message) {
                error.message = `Workspace Update Error: ${error.message}`;
            }
            log.error(error);
        }
        this.workspace.addChangeListener(this.props.vm.blockListener);

        if (this.props.vm.editingTarget && this.props.workspaceMetrics.targets[this.props.vm.editingTarget.id]) {
            const {scrollX, scrollY, scale} = this.props.workspaceMetrics.targets[this.props.vm.editingTarget.id];
            this.workspace.scrollX = scrollX;
            this.workspace.scrollY = scrollY;
            this.workspace.scale = scale;
            this.workspace.resize();
        }

        // Clear the undo state of the workspace since this is a
        // fresh workspace and we don't want any changes made to another sprites
        // workspace to be 'undone' here.
        this.workspace.clearUndo();
    }
    handleMonitorsUpdate (monitors) {
        // Update the checkboxes of the relevant monitors.
        // TODO: What about monitors that have fields? See todo in scratch-vm blocks.js changeBlock:
        // https://github.com/LLK/scratch-vm/blob/2373f9483edaf705f11d62662f7bb2a57fbb5e28/src/engine/blocks.js#L569-L576
        const flyout = this.workspace.getFlyout();
        for (const monitor of monitors.values()) {
            const blockId = monitor.get('id');
            const isVisible = monitor.get('visible');
            flyout.setCheckboxState(blockId, isVisible);
            // We also need to update the isMonitored flag for this block on the VM, since it's used to determine
            // whether the checkbox is activated or not when the checkbox is re-displayed (e.g. local variables/blocks
            // when switching between sprites).
            const block = this.props.vm.runtime.monitorBlocks.getBlock(blockId);
            if (block) {
                block.isMonitored = isVisible;
            }
        }
    }
    handleExtensionAdded (categoryInfo) {

        const defineBlocks = blockInfoArray => {
            if (blockInfoArray && blockInfoArray.length > 0) {
                const staticBlocksJson = [];
                const dynamicBlocksInfo = [];
                blockInfoArray.forEach(blockInfo => {
                    if (blockInfo.info && blockInfo.info.isDynamic) {
                        dynamicBlocksInfo.push(blockInfo);
                    } else if (blockInfo.json) {
                        staticBlocksJson.push(blockInfo.json);
                    }
                    // otherwise it's a non-block entry such as '---'
                });

                this.ScratchBlocks.defineBlocksWithJsonArray(staticBlocksJson);
                dynamicBlocksInfo.forEach(blockInfo => {
                    // This is creating the block factory / constructor -- NOT a specific instance of the block.
                    // The factory should only know static info about the block: the category info and the opcode.
                    // Anything else will be picked up from the XML attached to the block instance.
                    const extendedOpcode = `${categoryInfo.id}_${blockInfo.info.opcode}`;
                    const blockDefinition =
                        defineDynamicBlock(this.ScratchBlocks, categoryInfo, blockInfo, extendedOpcode);
                    this.ScratchBlocks.Blocks[extendedOpcode] = blockDefinition;
                });
            }
        };

        // scratch-blocks implements a menu or custom field as a special kind of block ("shadow" block)
        // these actually define blocks and MUST run regardless of the UI state
        defineBlocks(
            Object.getOwnPropertyNames(categoryInfo.customFieldTypes)
                .map(fieldTypeName => categoryInfo.customFieldTypes[fieldTypeName].scratchBlocksDefinition));
        defineBlocks(categoryInfo.menus);
        defineBlocks(categoryInfo.blocks);

        this.workspace.toolbox_.addedCategoryIds_.push(categoryInfo.id);
        this.ScratchBlocks = VMScratchBlocks(this.props.vm);

        // Update the toolbox with new blocks if possible
        const toolboxXML = this.getToolboxXML();
        if (toolboxXML) {
            this.props.updateToolboxState(toolboxXML);
        }

        // TODO: 연결모달 자동으로 띄우는 기능 복구
        const extension = extensionData.find(ext => ext.extensionId === categoryInfo.id);
        if (extension) {
            // if (extension.extensionId === 'nemo' && IS_IN_SEMOBAE) {
            //     this.handleConnectionModalStart('nemo');
            // }
            if (extension.extensionId === 'markCoding') {
                this.setState({codingButtonVisible: true});
                // if (IS_IN_SEMOBAE) {
                //     this.handleConnectionModalStart('markCoding');
                // }
            }
        }
    }
    handleBlocksInfoUpdate (categoryInfo) {
        // @todo Later we should replace this to avoid all the warnings from redefining blocks.
        this.handleExtensionAdded(categoryInfo);
    }
    handleCategorySelected (categoryId) {
        const extension = extensionData.find(ext => ext.extensionId === categoryId);
        if (extension && extension.launchPeripheralConnectionFlow) {
            if (categoryId === 'mecanumWheel') {
                this.handleConnectionModalStart('malrang');
            } else {
                this.handleConnectionModalStart(categoryId);
            }
        }

        this.withToolboxUpdates(() => {
            this.workspace.toolbox_.setSelectedCategoryById(categoryId);
        });
    }
    setBlocks (blocks) {
        this.blocks = blocks;
    }
    handlePromptStart (message, defaultValue, callback, optTitle, optVarType) {
        const p = {prompt: {callback, message, defaultValue}};
        p.prompt.title = optTitle ? optTitle :
            this.ScratchBlocks.Msg.VARIABLE_MODAL_TITLE;
        p.prompt.varType = typeof optVarType === 'string' ?
            optVarType : this.ScratchBlocks.SCALAR_VARIABLE_TYPE;
        p.prompt.showVariableOptions = // This flag means that we should show variable/list options about scope
            optVarType !== this.ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE &&
            p.prompt.title !== this.ScratchBlocks.Msg.RENAME_VARIABLE_MODAL_TITLE &&
            p.prompt.title !== this.ScratchBlocks.Msg.RENAME_LIST_MODAL_TITLE;
        p.prompt.showCloudOption = (optVarType === this.ScratchBlocks.SCALAR_VARIABLE_TYPE) && this.props.canUseCloud;
        this.setState(p);
    }
    handleConnectionModalStart (extensionId) {
        this.props.onOpenConnectionModal(extensionId);
    }
    handleStatusButtonUpdate () {
        this.ScratchBlocks.refreshStatusButtons(this.workspace);
    }
    handleDevicePairingUpdate () {
        this.ScratchBlocks.refreshDeviceStatusImages(this.workspace);
    }
    handleOpenSoundRecorder () {
        this.props.onOpenSoundRecorder();
    }

    handleTeachableMachineStart () {
        this.setState({customTeachableMachineVisible: true});
        this.props.updateConnectionModalExtensionId('teachableMachine');
    }

    handleTeachableMachineClose () {
        this.setState({customTeachableMachineVisible: false});
    }

    startMcPIP () {
        this.props.vm.extensionManager.startMcPIP();
    }

    setDroneMode () {
        this.props.vm.stopAll();
        this.props.vm.extensionManager.setDroneMode('codingRider');
    }
    setSimulatorMode () {
        this.props.vm.stopAll();
        this.props.vm.extensionManager.setSimulatorMode('codingRider');
    }

    startSettingMecanumwheel () {
        this.props.onOpenMecanumWheelModal();
    }

    startUpdateFirmware (extensionId) {
        switch (extensionId) {
            case 'nemo':
                if (this.props.vm.runtime.getPeripheralIsConnected('nemo')) {
                    this.props.updateHex();
                }
                this.props.onOpenConnectionModal(extensionId);
                break;
            case 'newmalrang':
                if (this.props.vm.runtime.getPeripheralIsConnected('newmalrang')) {
                    this.props.updateFirmware();
                }
                this.props.onOpenConnectionModal(extensionId);
            default:
                break;
        }
    }

    startTrainingTMModel () {
        this.props.onOpenTmTrainingModal();
    }
    /*
     * Pass along information about proposed name and variable options (scope and isCloud)
     * and additional potentially conflicting variable names from the VM
     * to the variable validation prompt callback used in scratch-blocks.
     */
    handlePromptCallback (input, variableOptions) {
        this.state.prompt.callback(
            input,
            this.props.vm.runtime.getAllVarNamesOfType(this.state.prompt.varType),
            variableOptions);
        this.handlePromptClose();
    }
    handlePromptClose () {
        this.setState({prompt: null});
    }
    handleCustomProceduresClose (data) {
        this.props.onRequestCloseCustomProcedures(data);
        const ws = this.workspace;
        setImmediate(() => {
            ws.refreshToolboxSelection_();
            ws.toolbox_.scrollToCategoryById('myBlocks');
        });
    }
    handleDrop (dragInfo) {
        fetch(dragInfo.payload.bodyUrl)
            .then(response => response.json())
            .then(blocks => this.props.vm.shareBlocksToTarget(blocks, this.props.vm.editingTarget.id))
            .then(() => {
                this.props.vm.refreshWorkspace();
                this.updateToolbox(); // To show new variables/custom blocks
            });
    }
    handleFlyoutVisibilityChangeIfAutoCloseEnabled (flyoutVisibility) {
        this.setState({flyoutVisibility});
    }

    initDroneModeChangeButtons(extensionId) {
        if (extensionId === 'codingRider') {
            const droneModeButton = document.querySelector('g[data-id="SET_DRONE_MODE"]');
            const simulatorModeButton = document.querySelector('g[data-id="SET_SIMULATOR_MODE"]');
            if (!droneModeButton || !simulatorModeButton) {
                log.error('Drone mode button or simulator mode button is not found');
                return;
            }
            const droneModeButtonText = droneModeButton.querySelector('text');
            const simulatorModeButtonText = simulatorModeButton.querySelector('text');
            droneModeButtonText.textContent = this.props.intl.formatMessage(messages.droneMode);
            simulatorModeButtonText.textContent = this.props.intl.formatMessage(messages.simulatorMode);
            droneModeButton.classList.add('disable-hover');
            simulatorModeButton.classList.add('disable-hover');
        }
    }

    handleDisableSimulator(extensionId) {
        if (extensionId === 'codingRider' || extensionId === 'codingDrone') {
            this.props.setDroneMode();
            this.props.vm.extensionManager.setDroneMode(extensionId);
            this.initDroneModeChangeButtons(extensionId);
        }
    }

    render () {
        /* eslint-disable no-unused-vars */
        const {
            anyModalVisible,
            canUseCloud,
            customProceduresVisible,
            extensionLibraryVisible,
            options,
            stageSize,
            vm,
            isRtl,
            isVisible,
            onActivateColorPicker,
            onOpenConnectionModal,
            onOpenSoundRecorder,
            customTMModalVisible,
            toggleVisibleTMModal,
            tmSelectedCamera,
            tmIsAvailableVideo,
            updateSelectedCamera,
            updateIsAvailableVideo,
            updateToolboxState,
            onActivateCustomProcedures,
            onRequestCloseExtensionLibrary,
            onRequestCloseCustomProcedures,
            toolboxXML,
            updateMetrics: updateMetricsProp,
            workspaceMetrics,
            isSemobae,
            updateScratchBlocks,
            updateWorkspace,
            updateAutoCloseChangeHandler,
            updateAnyPeripheralConnected,
            updateAnyBlockUsedInExtension,
            updateAlertPosition,
            updateConnectionModalExtensionId,
            onOpenMecanumWheelModal,
            onOpenTmTrainingModal,
            showAllBlockCategories,
            isMissionMode,
            malrangTutorialModalVisible,
            semoclassExtensions,
            autoClose,
            isToolboxEditable,
            ...props
        } = this.props;
        /* eslint-enable no-unused-vars */

        return (
            <React.Fragment>
                <DroppableBlocks
                    componentRef={this.setBlocks}
                    onDrop={this.handleDrop}
                    {...props}
                />
                {this.state.prompt ? (
                    <Prompt
                        defaultValue={this.state.prompt.defaultValue}
                        isStage={vm.runtime.getEditingTarget().isStage}
                        showListMessage={this.state.prompt.varType === this.ScratchBlocks.LIST_VARIABLE_TYPE}
                        label={this.state.prompt.message}
                        showCloudOption={this.state.prompt.showCloudOption}
                        showVariableOptions={this.state.prompt.showVariableOptions}
                        title={this.state.prompt.title}
                        vm={vm}
                        onCancel={this.handlePromptClose}
                        onOk={this.handlePromptCallback}
                    />
                ) : null}
                {extensionLibraryVisible ? (
                    <ExtensionLibrary
                        vm={vm}
                        onCategorySelected={this.handleCategorySelected}
                        onRequestClose={onRequestCloseExtensionLibrary}
                        isSemobae={isSemobae}
                    />
                ) : null}
                {customProceduresVisible ? (
                    <CustomProcedures
                        options={{
                            media: options.media
                        }}
                        onRequestClose={this.handleCustomProceduresClose}
                    />
                ) : null}
                {this.state.customTeachableMachineVisible ? (
                    // TODO: 디폴트 밸류, 스테이지, 라벨.. 등 넘겨주기
                    <CustomTeachableMachine
                        vm={vm}
                        onDialogClose={this.handleTeachableMachineClose}
                    />
                ) : null}
                {this.state.codingButtonVisible ? (
                    <CodingPlayButton
                        vm={vm}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

Blocks.propTypes = {
    anyModalVisible: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    customProceduresVisible: PropTypes.bool,
    extensionLibraryVisible: PropTypes.bool,
    isRtl: PropTypes.bool,
    isVisible: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    messages: PropTypes.objectOf(PropTypes.string),
    onActivateColorPicker: PropTypes.func,
    onActivateCustomProcedures: PropTypes.func,
    onOpenConnectionModal: PropTypes.func,
    toggleVisibleTMModal: PropTypes.func,
    updateSelectedCamera: PropTypes.func,
    updateIsAvailableVideo: PropTypes.func,
    customTMModalVisible: PropTypes.bool,
    tmSelectedCamera: PropTypes.string,
    tmIsAvailableVideo: PropTypes.bool,
    onOpenSoundRecorder: PropTypes.func,
    onRequestCloseCustomProcedures: PropTypes.func,
    onRequestCloseExtensionLibrary: PropTypes.func,
    options: PropTypes.shape({
        media: PropTypes.string,
        zoom: PropTypes.shape({
            controls: PropTypes.bool,
            wheel: PropTypes.bool,
            startScale: PropTypes.number
        }),
        colours: PropTypes.shape({
            workspace: PropTypes.string,
            flyout: PropTypes.string,
            toolbox: PropTypes.string,
            toolboxSelected: PropTypes.string,
            scrollbar: PropTypes.string,
            scrollbarHover: PropTypes.string,
            insertionMarker: PropTypes.string,
            insertionMarkerOpacity: PropTypes.number,
            fieldShadow: PropTypes.string,
            dragShadowOpacity: PropTypes.number
        }),
        comments: PropTypes.bool,
        collapse: PropTypes.bool,
        intl: intlShape.isRequired,
    }),
    intl: intlShape.isRequired,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    toolboxXML: PropTypes.string,
    updateMetrics: PropTypes.func,
    updateToolboxState: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
    workspaceMetrics: PropTypes.shape({
        targets: PropTypes.objectOf(PropTypes.object)
    }),
    isToolboxEditable: PropTypes.bool,
    updateAnyBlockUsedInExtension: PropTypes.func,
    updateAnyPeripheralConnected: PropTypes.func,
    updateAlertPosition: PropTypes.func,
    isSemobae: PropTypes.bool,
    isMissionMode: PropTypes.bool,
};

Blocks.defaultOptions = {
    zoom: {
        controls: true,
        wheel: true,
        startScale: BLOCKS_DEFAULT_SCALE
    },
    grid: {
        spacing: 40,
        length: 2,
        colour: '#ddd'
    },
    colours: {
        workspace: '#F9F9F9',
        flyout: '#F9F9F9',
        toolbox: '#FFFFFF',
        toolboxSelected: '#E9EEF2',
        scrollbar: '#CECDCE',
        scrollbarHover: '#CECDCE',
        insertionMarker: '#000000',
        insertionMarkerOpacity: 0.2,
        fieldShadow: 'rgba(255, 255, 255, 0.3)',
        dragShadowOpacity: 0.6
    },
    comments: true,
    collapse: false,
    sounds: false
};

Blocks.defaultProps = {
    isVisible: true,
    options: Blocks.defaultOptions
};

const mapStateToProps = state => {
    const toolboxState = state.scratchGui.toolbox;
    return {
        anyModalVisible: (
            Object.keys(state.scratchGui.modals).some(key => state.scratchGui.modals[key]) ||
        state.scratchGui.mode.isFullScreen
        ),
        extensionLibraryVisible: state.scratchGui.modals.extensionLibrary,
        isRtl: state.locales.isRtl,
        locale: state.locales.locale,
        messages: state.locales.messages,
        toolboxXML: state.scratchGui.toolbox.toolboxXML,
        customProceduresVisible: state.scratchGui.customProcedures.active,
        customTMModalVisible: state.scratchGui.customTMModal.visible,
        tmSelectedCamera: state.scratchGui.customTMModal.selectedCamera,
        tmIsAvailableVideo: state.scratchGui.customTMModal.isAvailableVideo,
        workspaceMetrics: state.scratchGui.workspaceMetrics,
        isToolboxEditable: isToolboxEditable(toolboxState),
        autoClose: state.scratchGui.autoCloseState.autoClose,
        semoclassExtensions: state.scratchGui.semobaeState.semoclassExtensions,
        malrangTutorialModalVisible: state.scratchGui.modals.malrangTutorial,
        isMissionMode: state.scratchGui.mode.isMissionMode,
        showAllBlockCategories: state.scratchGui.malrangTutorialState.showAllBlockCategories,
        isSimulatorMode: state.scratchGui.mode.isSimulatorMode,
    };
};

const mapDispatchToProps = dispatch => ({
    onActivateColorPicker: callback => dispatch(activateColorPicker(callback)),
    onActivateCustomProcedures: (data, callback) => dispatch(activateCustomProcedures(data, callback)),
    onOpenConnectionModal: id => {
        dispatch(setConnectionModalExtensionId(id));
        dispatch(openConnectionModal());
    },
    onOpenTmTrainingModal: () => {
        dispatch(openTMTrainingModal());
    },
    onOpenMecanumWheelModal: () => {
        dispatch(openMecanumWheelModal());
    },
    onOpenSoundRecorder: () => {
        dispatch(activateTab(SOUNDS_TAB_INDEX));
        dispatch(openSoundRecorder());
    },
    onRequestCloseExtensionLibrary: () => {
        dispatch(closeExtensionLibrary());
    },
    onRequestCloseCustomProcedures: data => {
        dispatch(deactivateCustomProcedures(data));
    },
    toggleVisibleTMModal: isVisible => {
        dispatch(setVisibleTMModal(isVisible));
    },
    updateSelectedCamera: selectedCamera => {
        dispatch(setSelectedCamera(selectedCamera));
    },
    updateIsAvailableVideo: isAvailableVideo => {
        dispatch(setAvailableVideo(isAvailableVideo));
    },
    updateToolboxState: toolboxXML => {
        dispatch(updateToolbox(toolboxXML));
    },
    updateMetrics: metrics => {
        dispatch(updateMetrics(metrics));
    },
    updateConnectionModalExtensionId: id => {
        dispatch(setConnectionModalExtensionId(id));
    },
    updateAlertPosition: y => {
        dispatch(setPositionYofAlert(y));
    },
    updateAnyBlockUsedInExtension: used => {
        dispatch(setAnyBlockUsedInExtension(used));
    },
    updateAnyPeripheralConnected: connected => {
        dispatch(setAnyPeripheralConnected(connected));
    },
    updateAutoCloseChangeHandler: handler => {
        dispatch(setAutoCloseChangeHandler(handler));
    },
    updateWorkspace: workspace => {
        dispatch(setWorkspace(workspace));
    },
    updateScratchBlocks: blocks => {
        dispatch(setScratchBlocks(blocks));
    },
    updateHex: () => {
        dispatch(setUpdateHex(true));
    },
    updateFirmware: () => {
        dispatch(setUpdateFirmware(true));
    },
    setDroneMode: () => {
        dispatch(setDroneMode());
    },
    setSimulatorMode: () => {
        dispatch(setSimulatorMode());
    }
});

export default errorBoundaryHOC('Blocks')(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Blocks))
);
