import React, { useEffect, useRef, useState } from 'react';
import { formatTime } from '../../../lib/utils/time';

const Timer = ({
    isActive
}) => {
    const [time, setTime] = useState(0);
    
    const interval = useRef(null);

    useEffect(() => {
        if (isActive) {
            setTime(0);

            interval.current = setInterval(() => {
                setTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(interval.current);
        }

        return () => {
            clearInterval(interval.current);
        };
    }, [isActive]);

    return (
        <span>{formatTime(time)}</span>
    );
};

export default Timer;