import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {STAGE_DISPLAY_SIZES} from '../lib/layout-constants.js';
import StageWrapperComponent from '../components/stage-wrapper/stage-wrapper.jsx';

const StageWrapper = props => <StageWrapperComponent {...props} />;

StageWrapper.propTypes = {
    isRendererSupported: PropTypes.bool.isRequired,
    isSimulatorMode: PropTypes.bool.isRequired,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => {
    return {
        isSimulatorMode: state.scratchGui.mode.isSimulatorMode,
    };
}

export default injectIntl(connect(
    mapStateToProps
)(StageWrapper));

