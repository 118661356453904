const MIC_ACTIVE = 'scratch-gui/mic-active';
const MIC_CONNECTED = 'scratch-gui/mic-connected';
const CAMERA_ACTIVE = 'scratch-gui/camera-active';
const CAMERA_CONNECTED = 'scratch-gui/camera-connected';

const initialState = {
    micActive: false,
    isMicConnected: false,
    cameraActive: false,
    isCameraConnected: false,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case MIC_ACTIVE:
        return Object.assign({}, state, {
            micActive: action.active,
        });
    case MIC_CONNECTED:
        return Object.assign({}, state, {
            isMicConnected: action.isConnected,
        });
    case CAMERA_ACTIVE:
        return Object.assign({}, state, {
            cameraActive: action.active,
        });
    case CAMERA_CONNECTED:
        return Object.assign({}, state, {
            isCameraConnected: action.isConnected
        });
    default:
        return state;
    }
};

const setMicActive = function (active) {
    return {
        type: MIC_ACTIVE,
        active: active
    };
};

const setIsMicConnected = function (isMicConnected) {
    return {
        type: MIC_CONNECTED,
        isConnected: isMicConnected
    };
};

const setCameraActive = function (active) {
    return {
        type: CAMERA_ACTIVE,
        active: active
    };
};

const setIsCameraConnected = function (isCameraConnected) {
    return {
        type: CAMERA_CONNECTED,
        isConnected: isCameraConnected
    };
};

export {
    reducer as default,
    initialState as mediaActiveInitialState,
    setMicActive,
    setIsMicConnected,
    setCameraActive,
    setIsCameraConnected
};
