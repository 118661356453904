import { hasCordova, IS_ANDROID } from "./utils/android";

const blobToBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result.split(',')[1]); // `data:*/*;base64,` 부분 제거
        reader.onerror = reject;
    });
}

export default (filename, blob) => {
    if (IS_ANDROID) {
        return blobToBase64(blob)
            .then((content) => {
                if (hasCordova) {
                    window.cordova.plugins.safMediastore.saveFile({
                        filename,
                        data: content,
                    });
                } else {
                    window.semobaeAPI.downloadMediastoreFile(filename, content);
                }
            })
            .catch((error) => {
                window.alert('파일 다운로드에 실패했습니다.');
                console.error('파일 다운로드 실패', error);
            });
    }

    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    // Use special ms version if available to get it working on Edge.
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
        return;
    }

    if ('download' in HTMLAnchorElement.prototype) {
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.type = blob.type;
        downloadLink.click();
        // remove the link after a timeout to prevent a crash on iOS 13 Safari
        window.setTimeout(() => {
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url);
        }, 1000);
    } else {
        // iOS 12 Safari, open a new page and set href to data-uri
        let popup = window.open('', '_blank');
        const reader = new FileReader();
        reader.onloadend = function () {
            popup.location.href = reader.result;
            popup = null;
        };
        reader.readAsDataURL(blob);
    }

};
