import React, { useEffect, useRef, useCallback } from 'react';
import styles from './line.css';

const calculatePath = (box1, box2) => {
    const box1Rect = box1.getBoundingClientRect();
    const box2Rect = box2.getBoundingClientRect();

    const startX = box1Rect.width;
    const startY = box1Rect.height / 2;
    const endX = box2Rect.left - box1Rect.left;
    const endY = box2Rect.height / 2 + box2Rect.top - box1Rect.top;

    return `M${startX} ${startY} C${startX + 50} ${startY}, ${endX - 50} ${endY}, ${endX} ${endY}`;
};

const TrainingLine = ({
    containerRef,
    startRefToDrawPath,
    targetRefToDrawPath
}) => {
    const lineRef = useRef(null);

    const drawPath = useCallback(() => {
        if(!lineRef) {
            return;
        }

        lineRef.current.children[0].setAttribute('d', calculatePath(startRefToDrawPath.current, targetRefToDrawPath.current));
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            containerRef.current?.dispatchEvent(new Event('scroll'));
        });

        containerRef.current.addEventListener('scroll', drawPath);
        resizeObserver.observe(containerRef.current);
        resizeObserver.observe(startRefToDrawPath.current);
        resizeObserver.observe(targetRefToDrawPath.current);

        setTimeout(() => {    
            drawPath();
        }, 0);

        return () => {
            containerRef.current?.removeEventListener('scroll', drawPath);
            resizeObserver.disconnect();
        };
    }, [drawPath]);
    
    return (
        <svg
            className={styles.line}
            ref={lineRef}
        >
            <path id="path1" d="" />
        </svg>
    );
}

export default TrainingLine;