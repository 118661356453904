import React, { memo } from 'react';
import { Line, LineChart, YAxis } from 'recharts';

const LineGraph = memo(({
    data,
    dataKey,
    wrapperClassName,
    chartClassName,
    width,
    height,
    min,
    max,
    strokeColor,
    allowDataOverflow,
}) => {
    return (
        <div className={wrapperClassName}>
            <LineChart
                width={width}
                height={height}
                data={data}
                className={chartClassName}
                margin={{
                    bottom: 0,
                    left: 0,
                }}
            >
                <YAxis
                    type="number"
                    domain={[min, max]}
                    dataKey={dataKey}
                    hide
                    allowDataOverflow={allowDataOverflow}
                />
                <Line
                    type="monotone"
                    dataKey="sensor"
                    stroke={strokeColor}
                    strokeWidth={2}
                    isAnimationActive={false}
                    dot={false}
                />
            </LineChart>
        </div>
    );
});

export default LineGraph;