import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import ScanningStep from '../../containers/scanning-step.jsx';
import AutoScanningStep from '../../containers/auto-scanning-step.jsx';
import ConnectingStep from './connecting-step.jsx';
import ConnectedStep from './connected-step.jsx';
import ErrorStep from './error-step.jsx';
import UnavailableStep from './unavailable-step.jsx';

import styles from './connection-modal.css';
import MarkCodingWindowSelectingStep from './mark-coding-window-selecting-step.jsx';
import MarkCodingMcErrorStep from './mark-coding-mc-error-step.jsx';

import {isByrobotDroneExtension} from '../../lib/isByrobotDroneEx.js';
import UpdateHexStep from './update-hex-step.jsx';
import classNames from 'classnames';
import UpdateFirmwareStep from './update-firmware-step.jsx';

const PHASES = keyMirror({
    scanning: null,
    connecting: null,
    connected: null,
    error: null,
    unavailable: null,
    marklink_connected: null,
    update_scratch_hex: null,
    update_firmware: null,
});


const isMarkCoding = (props) => {
    return props.extensionId === 'markCoding';
}

const ConnectionModalComponent = props => {
    if (isMarkCoding(props)) {
        return (
            <Modal
                className={styles.modalContent}
                contentLabel={props.name}
                headerClassName={styles.header}
                headerImage={props.connectionSmallIconURL}
                id="connectionModal"
                onHelp={null}
                onRequestClose={props.onCancel}
            >
                <Box className={styles.body}>
                    {props.phase === PHASES.scanning && <AutoScanningStep {...props} />}
                    {props.phase === PHASES.connecting && <ConnectingStep {...props} />}
                    {props.phase === PHASES.marklink_connected && <MarkCodingWindowSelectingStep {...props} />}
                    {props.phase === PHASES.connected && <ConnectedStep {...props} />}
                    {props.phase === PHASES.error && <MarkCodingMcErrorStep {...props} />}
                    {props.phase === PHASES.unavailable && <UnavailableStep {...props} />}
                </Box>
            </Modal>
        );
    }
    return (
        <Modal
            className={classNames(styles.modalContent, props.phase === PHASES.update_scratch_hex && styles.overflowVisible)}
            contentLabel={props.name}
            headerClassName={props.extensionId === 'nemo' ? styles.nemo_header : styles.header}
            headerImage={props.connectionSmallIconURL}
            id="connectionModal"
            onHelp={isByrobotDroneExtension(props.extensionId) ? null : props.onHelp}
            onRequestClose={props.onCancel}
        >
            <Box className={styles.body}>
                {props.phase === PHASES.scanning && !props.useAutoScan && <ScanningStep {...props} />}
                {props.phase === PHASES.scanning && props.useAutoScan && <AutoScanningStep {...props} />}
                {props.phase === PHASES.connecting && <ConnectingStep {...props} />}
                {props.phase === PHASES.connected && <ConnectedStep {...props} />}
                {props.phase === PHASES.error && <ErrorStep {...props} />}
                {props.phase === PHASES.unavailable && <UnavailableStep {...props} />}
                {props.phase === PHASES.update_scratch_hex && <UpdateHexStep {...props} />}
                {props.phase === PHASES.update_firmware && <UpdateFirmwareStep {...props} />}
            </Box>
        </Modal>
    );
};

ConnectionModalComponent.propTypes = {
    connectingMessage: PropTypes.node.isRequired,
    connectionSmallIconURL: PropTypes.string,
    connectionTipIconURL: PropTypes.string,
    name: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onHelp: PropTypes.func.isRequired,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired,
    title: PropTypes.string.isRequired,
    useAutoScan: PropTypes.bool.isRequired,
    extensionId: PropTypes.string,
    onStartMcStreaming: PropTypes.func,
};

ConnectionModalComponent.defaultProps = {
    connectingMessage: 'Connecting'
};

export {
    ConnectionModalComponent as default,
    PHASES
};
