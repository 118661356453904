import React from 'react';
import PropTypes from 'prop-types';
import {OrderedMap} from 'immutable';
import Draggable from 'react-draggable';
import CustomInputMonitor from '../../containers/custom-input-monitor.jsx';
import VM from 'scratch-vm';

import malringIcon from './malrang_icon.svg';
import technicIcon from './technic_icon.svg';

import styles from './custom-input-popup.css';
import { MONITOR_NAMES } from '../../containers/custom-input-popup.jsx';

const MONITOR_HEIGHT = 24;

const getMonitorIndex = monitorKey => {
    return [...MONITOR_NAMES].indexOf(monitorKey);
};

const getMonitorYPosition = monitorIndex => {
    return MONITOR_HEIGHT * monitorIndex;
};

const CustomInputPopupComponent = props => {
    const isTechnic = props.splitMonitorKey === 'technic_monitor';
    const icon = isTechnic ? technicIcon : malringIcon;
    
    const defaultY = React.useMemo(() => {
        const monitorIndex = getMonitorIndex(props.splitMonitorKey);
        const y = getMonitorYPosition(monitorIndex);

        return y;
    }, [props.splitMonitorKey]);

    const monitorStyle = React.useMemo(() => {
        let style = {};
        
        if (props.topMonitor === props.splitMonitorKey) {
            style = { zIndex: 100 };
        }

        return style;
    }, [props.splitMonitorKey, props.topMonitor]);

    return (
        <Draggable defaultPosition={{ x: 0, y: defaultY }} >
            <div
                className={`${styles.monitorContainer} ${isTechnic && styles.technicMonitorBg}`}
                onClick={() => props.onClickMonitor(props.splitMonitorKey)}
                style={monitorStyle}
            >
                <img src={icon} className={styles.icon} />
                <div className={styles.row} >
                    {props.monitors.map(monitor => (
                        <CustomInputMonitor
                            key={monitor.id}
                            id={monitor.id}
                            opcode={monitor.opcode}
                            params={monitor.params}
                            value={monitor.value}
                            vm={props.vm}
                            monitorName={props.splitMonitorKey}
                        />
                    )).toArray()}
                </div>
            </div>
        </Draggable>
    );
};

CustomInputPopupComponent.propTypes = {
    monitors: PropTypes.instanceOf(OrderedMap),
    vm: PropTypes.instanceOf(VM).isRequired
};

export default CustomInputPopupComponent;
