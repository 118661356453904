import React from 'react';
import bindAll from 'lodash.bindall';
import {connect} from 'react-redux';
import TmTrainingModalComponent from '../components/tm-training-modal/tm-training-modal.jsx';
import {closeTMTrainingModal} from '../reducers/modals.js';
import TmTraining from '../../tm-training/dist/tm-training.js';
import {uploadExtraFile, downloadExtraFile} from '../semobae_utils/semobae_utils.js';
import {setVisible, setHidden} from '../reducers/work-loading-state.js';
import downloadBlob from '../lib/download-blob.js';
import JSZip from 'jszip';
import isScratchDesktop from '../lib/isScratchDesktop.js';

const MODEL_JSON = 'model.json';
const SAMPLE_DATA_JSON = 'sampleData.json';

export const trainingSteps = {
    NONE: '학습 준비 단계',
    ADD_IMAGE_SAMPLE: '이미지 샘플 추가 단계',
    TRAINING_IMAGE: '이미지 학습 중 단계',
    TRAINED_IMAGE: '이미지 학습 완료 단계',
    UPLOAD_SAMPLE: '샘플 업로드 단계',
};

class TmTrainingModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'onOpenCameraViewOfClass',
            'onCloseCameraViewOfClass',
            'onDeviceChange',
            'onApplyModel',
            'onSaveSample',
            'isCameraViewOpened',
            'onAddNewClass',
            'onCloseModal',
            'onDeleteSample',
            'onClassMenuToggle',
            'isClassMenuOpened',
            'clearClass',
            'deleteClass',
            'openProjectMenu',
            'closeProjectMenu',
            'openNewProject',
            'loadProject',
            'onAddNewSample',
            'onSaveTmProjectLocal',
            'onLoadTmProjectLocal',
            'setNewTmInstance',
            'setNewProjectState',
            'startTrainingAllClasses',
            'onTrainedImage',
        ]);

        const modelData = this.props.vm.runtime.teachableMachineModelClassifierDataset;
        this.props.setVisibleWorkLoader();
        this.tmTrainingInstance = new TmTraining();
        this.tmTrainingInstance.init().then(async () => {
            this.tmTrainingInstance.start();
            if (modelData) {
                try {
                    let sampleData = null;
                    if (this.props.vm.runtime.teachableMachineSampleDataURL) {
                        sampleData = await this.downloadSampleData(this.props.vm.runtime.teachableMachineSampleDataURL);
                    } else if (this.props.vm.runtime.teachableMachineSampleData) {
                        sampleData = this.props.vm.runtime.teachableMachineSampleData;
                    }
                    
                    if (sampleData) {
                        await this.loadTmTrainingProject(modelData, sampleData);
                    } else {
                        console.warn('Failed to load sample data');
                    }
                } catch (err) {
                    console.warn(`Failed to load sample data: ${error}`);
                    this.loadTmTrainingProject(modelData);
                }
            }
        }).catch((err) => {
            console.warn('Failed to initialize TmTrainingInstance', err);
            alert('티처블머신을 초기화 하는 중 문제가 발생했습니다.');
        }).finally(() => {
            this.props.setHiddenWorkLoader();
        });
        this.state = {
            classIds: this.tmTrainingInstance.getClassIds(),
            cameraViewOpenedClassId: '',
            deviceId: this.tmTrainingInstance.videoDeviceId,
            isProjectMenuOpen: false,
            menuOpenedClassId: '',
            componentKey: 0,
            uploadedSample: null,
            currentTrainingStep: trainingSteps.NONE,
            imageCountInAllClass: 0,
            trainedImageCount: 0,
        };
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevState.componentKey !== this.state.componentKey) {
            this.tmTrainingInstance.start();
        }
    }

    componentWillUnmount () {
        this.tmTrainingInstance.renderTriggers.clear();
        this.tmTrainingInstance.predictionRenderTrigger = null;
    }

    onOpenCameraViewOfClass (id) {
        this.setState({
            cameraViewOpenedClassId: id
        });
    }

    onCloseCameraViewOfClass () {
        this.setState({
            cameraViewOpenedClassId: ''
        });
    }

    async onDeviceChange (deviceId) {
        await this.tmTrainingInstance.setVideoDevice(deviceId);
        this.setState(prev => ({...prev, deviceId}));
    }

    async onSaveSample () {
        this.props.setVisibleWorkLoader();
        this.setState({ currentTrainingStep: trainingSteps.UPLOAD_SAMPLE });

        const sampleData = this.tmTrainingInstance.getSampleData();
        if (!this.tmTrainingInstance.isOldData) {
            sampleData.version = 1;
        }
        const sampleDataId = crypto.randomUUID();
        const sampleDataURL = await uploadExtraFile(sampleData, this.getSampleDataPath(sampleDataId))
            .catch((err) => {
                this.setState({ currentTrainingStep: trainingSteps.TRAIN_IMAGE });
                console.error('Failed to upload sample data: ', err);
            });
        this.props.vm.runtime.teachableMachineSampleDataURL = sampleDataURL;
        this.setState({ uploadedSampleLink: sampleDataURL });
        this.props.setHiddenWorkLoader();
    }

    async onSaveTmProjectLocal () {
        this.props.setVisibleWorkLoader();
        const modelData = this.tmTrainingInstance.getModelData();
        const sampleData = this.tmTrainingInstance.getSampleData();
        if (!this.tmTrainingInstance.isOldData) {
            sampleData.version = 1;
        }

        const zip = new JSZip();

        zip.file('model.json', JSON.stringify(modelData));
        zip.file('sampleData.json', JSON.stringify(sampleData));

        await zip.generateAsync({
            type: 'blob',
            mimeType: 'application/zip',
            compression: 'DEFLATE',
            compressionOptions: {
                level: 6
            }
        }).then(async (content) => {
            await downloadBlob('sampleData.zip', content);
        });

        this.props.setHiddenWorkLoader();
        this.closeProjectMenu();
    }

    onLoadTmProjectLocal (e) {
        this.props.setVisibleWorkLoader();
        this.fileReader = new FileReader();
        this.fileReader.onload = async (e) => {
            try {
                const arrayBuffer = e.target.result;
                const zip = await JSZip.loadAsync(arrayBuffer);
                const content = await zip.file(MODEL_JSON).async('text');
                const modelData = JSON.parse(content);
                const content2 = await zip.file(SAMPLE_DATA_JSON).async('text');
                const sampleData = JSON.parse(content2);
                await this.loadProject(modelData, sampleData);
            } catch (e) {
                console.error('Failed to load sample data from zip file: ', e);
            } finally {
                this.props.setHiddenWorkLoader();
                this.closeProjectMenu();
            };
        };
        this.fileReader.readAsArrayBuffer(e.target.files[0]);
    }

    async onApplyModel () {
        this.tmTrainingInstance.stop();
        this.props.setVisibleWorkLoader();

        const modelData = this.tmTrainingInstance.getModelData();
        const link = this.state.uploadedSampleLink;

        await this.props.vm.extensionManager.setTeachableMachineBlocksWithOfflineModel(
            modelData, 
            { sampleDataURL: link }
        );
        this.props.vm.runtime.emitProjectChanged();
        this.props.onCloseTmTrainingModal();
        this.props.setHiddenWorkLoader();
    }

    isCameraViewOpened (id) {
        return this.state.cameraViewOpenedClassId === id;
    }

    onAddNewClass () {
        this.tmTrainingInstance.addNewClass();
        this.setState({classIds: this.tmTrainingInstance.getClassIds()});
    }

    onCloseModal () {
        this.tmTrainingInstance.stop();
        this.props.onCloseTmTrainingModal();
    }

    onDeleteSample (id, index) {
        this.tmTrainingInstance.deleteSample(id, index);
        this.setState({ currentTrainingStep: trainingSteps.ADD_IMAGE_SAMPLE });
    }

    onClassMenuToggle (id) {
        if (this.state.menuOpenedClassId === id) {
            this.setState({menuOpenedClassId: ''});
            return;
        }
        this.setState({menuOpenedClassId: id});
    }

    isClassMenuOpened (id) {
        return this.state.menuOpenedClassId === id;
    }

    clearClass (id) {
        this.tmTrainingInstance.clearClass(id);
    }

    deleteClass (classId) {
        this.tmTrainingInstance.deleteClass(classId);
        this.setState({classIds: this.tmTrainingInstance.getClassIds()});
    }

    openProjectMenu () {
        this.setState({isProjectMenuOpen: true});
    }

    closeProjectMenu () {
        this.setState({isProjectMenuOpen: false});
    }

    async openNewProject () {
        await this.setNewTmInstance();
        this.setNewProjectState();
    }

    async loadProject (modelData, sampleData) {
        await this.openNewProject();
        this.loadTmTrainingProject(modelData, sampleData);
    }

    async setNewTmInstance () {
        if (!this.tmTrainingInstance) {
            return;
        }
        this.tmTrainingInstance.stop();

        this.props.setVisibleWorkLoader();
        this.tmTrainingInstance = new TmTraining();
        await this.tmTrainingInstance.init().then(() => {
            this.tmTrainingInstance.start();
        }).catch((err) => {
            console.warn('Failed to initialize TmTrainingInstance', err);
            alert('티처블머신을 초기화 하는 중 문제가 발생했습니다.');
        }).finally(() => {
            this.props.setHiddenWorkLoader();
        });
    }

    setNewProjectState () {
        this.setState({
            classIds: this.tmTrainingInstance.getClassIds(),
            cameraViewOpenedClassId: '',
            deviceId: this.tmTrainingInstance.videoDeviceId,
            isProjectMenuOpen: false,
            menuOpenedClassId: '',
            componentKey: this.state.componentKey + 1,
        });
    }

    async loadTmTrainingProject (modelData, sampleData) {
        this.tmTrainingInstance.load(modelData, sampleData);
        this.setState({
            classIds: this.tmTrainingInstance.getClassIds()
        });
    }

    async downloadSampleData (url) {
        this.props.setVisibleWorkLoader();
        const sampleDataJSON = await downloadExtraFile(url).then(blob => blob.text()).finally(() => {
            this.props.setHiddenWorkLoader();
        });
        return JSON.parse(sampleDataJSON);
    }

    onAddNewSample () {
        this.setState({ currentTrainingStep: trainingSteps.ADD_IMAGE_SAMPLE });
    }

    getSampleDataPath (sampleDataId) {
        return `extension_file_archive/tm_sample_data/${sampleDataId}`;
    }

    startTrainingAllClasses () {
        this.setState({ currentTrainingStep: trainingSteps.TRAINING_IMAGE });

        this.tmTrainingInstance.trainAllClasses(this.onTrainedImage)
            .catch((err) => {
                alert('학습에 실패했습니다.');
                this.setState({ currentTrainingStep: trainingSteps.ADD_IMAGE_SAMPLE });
                console.error(err);
            }).then(() => {
                this.setState({ currentTrainingStep: trainingSteps.TRAINED_IMAGE });
            });
    }

    #calculateTrainedImageCount (allClass, classId, exampleIndex) {
        const currentClassIndex = allClass.findIndex(currentClass => currentClass.classId === classId);

        let trainedImageCount = 0;
        for (let i = 0; i < currentClassIndex; i++) {
            trainedImageCount += allClass[i].examples.length;
        }
        trainedImageCount += exampleIndex + 1;

        return trainedImageCount;
    }

    onTrainedImage (trainedImageInfo) {
        const {                    
            allClass,
            classId,
            exampleIndex,
        } = trainedImageInfo;

        const imageCountInAllClass = allClass.reduce((count, currentClass) => {
            return count + currentClass.examples.length;
        }, 0);

        const trainedImageCount = this.#calculateTrainedImageCount(allClass, classId, exampleIndex);

        this.setState({
            imageCountInAllClass,
            trainedImageCount,
        });
    }

    render () {
        return (<TmTrainingModalComponent
            key={this.state.componentKey}
            tmTrainingInstance={this.tmTrainingInstance}
            cameraViewOpenedClassLabel={this.state.cameraViewOpenedClassLabel}
            onOpenCameraViewOfClass={this.onOpenCameraViewOfClass}
            onCloseCameraViewOfClass={this.onCloseCameraViewOfClass}
            onCloseTmTrainingModal={this.onCloseModal}
            onDeviceChange={this.onDeviceChange}
            onApplyModel={this.onApplyModel}
            onSaveSample={this.onSaveSample}
            isCameraViewOpened={this.isCameraViewOpened}
            classIds={this.state.classIds}
            uploadedSampleLink={this.state.uploadedSampleLink}
            onAddNewClass={this.onAddNewClass}
            onDeleteSample={this.onDeleteSample}
            onClassMenuToggle={this.onClassMenuToggle}
            isClassMenuOpened={this.isClassMenuOpened}
            clearClass={this.clearClass}
            deleteClass={this.deleteClass}
            canDeleteAnyClass={this.tmTrainingInstance.canDeleteAnyClass()}
            isProjectMenuOpen={this.state.isProjectMenuOpen}
            onOpenProjectMenu={this.openProjectMenu}
            onCloseProjectMenu={this.closeProjectMenu}
            onOpenNewProject={this.openNewProject}
            onAddNewSample={this.onAddNewSample}
            onSaveProject={this.onSaveTmProjectLocal}
            onLoadProject={this.onLoadTmProjectLocal}
            setVisibleWorkLoader={this.props.setVisibleWorkLoader}
            setHiddenWorkLoader={this.props.setHiddenWorkLoader}
            startTrainingAllClasses={this.startTrainingAllClasses}
            currentTrainingStep={this.state.currentTrainingStep}
            imageCountInAllClass={this.state.imageCountInAllClass}
            trainedImageCount={this.state.trainedImageCount}
        />);
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onCloseTmTrainingModal: () => {
        dispatch(closeTMTrainingModal());
    },
    setVisibleWorkLoader: () => {
        dispatch(setVisible('teachableMachine', 'Loading...'));
    },
    setHiddenWorkLoader: () => {
        dispatch(setHidden());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TmTrainingModal);
