import React from 'react';

import AddClassButtonIcon from '../add-class-button.jsx';
import ClassContainer from '../class-container/class-container.jsx';
import style from '../tm-training-modal.css';

const ClassesContainer = ({
    tmTrainingInstance,
    classIds,
    onAddNewClass,
    onDeleteSample,
    onDeviceChange,
    onOpenCameraViewOfClass,
    onCloseCameraViewOfClass,
    isCameraViewOpened,
    onClassMenuToggle,
    isClassMenuOpened,
    clearClass,
    deleteClass,
    canDeleteAnyClass,
    onAddNewSample,
    targetRefToDrawPath,
    tmModalContainerRef
}) => {
    return (
        <div className={style.classContainerWrapper}>
            {classIds.map(id => (<ClassContainer
                key={id}
                classId={id}
                tmTrainingInstance={tmTrainingInstance}
                video={tmTrainingInstance.video}
                isCameraViewOpened={isCameraViewOpened(id)}
                onDeviceChange={onDeviceChange}
                onOpenCameraViewOfClass={onOpenCameraViewOfClass}
                onCloseCameraViewOfClass={onCloseCameraViewOfClass}
                onDeleteSample={index => onDeleteSample(id, index)}
                onClassMenuToggle={() => onClassMenuToggle(id)}
                isClassMenuOpened={isClassMenuOpened(id)}
                clear={() => clearClass(id)}
                delete={() => deleteClass(id)}
                canDelete={canDeleteAnyClass}
                onAddNewSample={onAddNewSample}
                targetRefToDrawPath={targetRefToDrawPath}
                tmModalContainerRef={tmModalContainerRef}
            />)
            )}
            <button
                className={style.addClassButton}
                onClick={onAddNewClass}
            >
                <AddClassButtonIcon />
                {'클래스 추가'}
            </button>
        </div>
    );
};

export default ClassesContainer;