import React, { useEffect, useRef, useState } from 'react';
import resultStyles from '../training-result/training-result.css';
import statusStyles from './training-status-section.css';
import classNames from 'classnames';
import Timer from './timer.jsx';
import TrainingButton from './training-button.jsx';
import TrainingLine from '../line/training-line.jsx';

const TrainingStatusSection = ({
    statusContainerRef,
    tmModalContainerRef,
    resultContainerRef,
    onStartTraining,
    isTrainingAvailable,
    imageCountInAllClass,
    trainedImageCount,
    isTraining,
    isTrained,
}) => {
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            const containerHeight = entries[0].contentRect.height;
            statusContainerRef.current?.style.setProperty('--container-height', `${containerHeight}px`);
        });
        resizeObserver.observe(statusContainerRef.current);

        return () => {
            resizeObserver.disconnect();
        }
    }, []);

    return (
        <div
            className={classNames(resultStyles.sectionContainer, statusStyles.traningStatusSection)}
            ref={statusContainerRef}
        >
            <TrainingLine
                containerRef={tmModalContainerRef}
                startRefToDrawPath={statusContainerRef}
                targetRefToDrawPath={resultContainerRef}
            />
            <div className={statusStyles.sectionContent}>
                <span className={statusStyles.statusTitle}>학습</span>
                <TrainingButton
                    isTrainingAvailable={isTrainingAvailable}
                    isTraining={isTraining}
                    isTrained={isTrained}
                    trainedImageCount={trainedImageCount}
                    imageCountInAllClass={imageCountInAllClass}
                    onStartTraining={onStartTraining}
                />
                {isTraining && (
                    <div className={statusStyles.timerContainer}>
                        <Timer isActive={isTraining} /> - {trainedImageCount} / {imageCountInAllClass}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrainingStatusSection;