import React from 'react';
import classNames from 'classnames';
import Modal from '../../containers/modal.jsx';
import style from './tm-training-modal.css';
import TrainingResult from './training-result/training-result.jsx';
import ProjectMenu from './project-menu/project-menu.jsx';
import ClassesContainer from './class-container/classes-container.jsx';
import { connect } from 'react-redux';
import { setHidden, setVisible } from '../../reducers/work-loading-state.js';
import TrainingStatusSection from './training-section/traning-status-section.jsx';
import { trainingSteps } from '../../containers/tm-training-modal.jsx';
import isScratchDesktop from '../../lib/isScratchDesktop.js';
import { isDroneScratchAndroid } from '../../lib/utils/android.js';

class TmTrainingModalComponent extends React.Component {
    constructor (props) {
        super(props);
        this.resultContainerRef = React.createRef();
        this.tmModalContainerRef = React.createRef();
        this.statusContainerRef = React.createRef();
    }

    render () {
        const {
            onOpenCameraViewOfClass,
            onCloseCameraViewOfClass,
            tmTrainingInstance,
            onCloseTmTrainingModal,
            onDeviceChange,
            onApplyModel,
            onSaveSample,
            isCameraViewOpened,
            classIds,
            onAddNewClass,
            onDeleteSample,
            onClassMenuToggle,
            isClassMenuOpened,
            clearClass,
            deleteClass,
            canDeleteAnyClass,
            isProjectMenuOpen,
            onOpenProjectMenu,
            onCloseProjectMenu,
            onOpenNewProject,
            uploadedSampleLink,
            onAddNewSample,
            onSaveProject,
            onLoadProject,
            startTrainingAllClasses,
            currentTrainingStep,
            imageCountInAllClass,
            trainedImageCount,
            setVisibleWorkLoader,
            setHiddenWorkLoader
        } = this.props;
        const CONTENT_TITLE = '티처블머신 모델 학습';

        const shouldUploadSample = !isScratchDesktop() && !isDroneScratchAndroid();

        const isUploadAvailable = shouldUploadSample && currentTrainingStep === trainingSteps.TRAINED_IMAGE;
        const isApplyAvailable = shouldUploadSample ? currentTrainingStep === trainingSteps.UPLOAD_SAMPLE : currentTrainingStep === trainingSteps.TRAINED_IMAGE;

        const isDisabledClick = currentTrainingStep === trainingSteps.TRAINING_IMAGE;

        return (<Modal
            fullScreen
            contentLabel={CONTENT_TITLE}
            onRequestClose={onCloseTmTrainingModal}
        >
            <div
                className={style.container}
                ref={this.tmModalContainerRef}
                style={{
                    pointerEvents: isDisabledClick ? 'none' : 'auto'
                }}
            >
                <div
                    className={classNames(style.menuButton, isProjectMenuOpen && style.active)}
                    onClick={isProjectMenuOpen ? onCloseProjectMenu : onOpenProjectMenu}
                >
                    <div className={style.hamburgerLines}>
                        <span className={style.line} />
                        <span className={style.line} />
                        <span className={style.line} />
                    </div>
                    메뉴
                </div>
                {isProjectMenuOpen && (
                    <ProjectMenu
                        onLoadProject={onLoadProject}
                        onOpenNewProject={onOpenNewProject}
                        onSaveProject={onSaveProject}
                    />
                )}
                <div className={style.columnContainer}>
                    <div className={style.classContainerColumn}>
                        <ClassesContainer
                            canDeleteAnyClass={canDeleteAnyClass}
                            classIds={classIds}
                            clearClass={clearClass}
                            deleteClass={deleteClass}
                            isCameraViewOpened={isCameraViewOpened}
                            isClassMenuOpened={isClassMenuOpened}
                            onAddNewClass={onAddNewClass}
                            onAddNewSample={onAddNewSample}
                            onCloseCameraViewOfClass={onCloseCameraViewOfClass}
                            onDeleteSample={onDeleteSample}
                            onDeviceChange={onDeviceChange}
                            onOpenCameraViewOfClass={onOpenCameraViewOfClass}
                            onClassMenuToggle={onClassMenuToggle}
                            targetRefToDrawPath={this.statusContainerRef}
                            tmModalContainerRef={this.tmModalContainerRef}
                            tmTrainingInstance={tmTrainingInstance}
                        />
                    </div>
                    <div className={style.trainingResultColumn} >
                        <TrainingStatusSection
                            onStartTraining={startTrainingAllClasses}
                            statusContainerRef={this.statusContainerRef}
                            tmModalContainerRef={this.tmModalContainerRef}
                            resultContainerRef={this.resultContainerRef}
                            isTrainingAvailable={currentTrainingStep === trainingSteps.ADD_IMAGE_SAMPLE}
                            isTraining={currentTrainingStep === trainingSteps.TRAINING_IMAGE}
                            isTrained={currentTrainingStep === trainingSteps.TRAINED_IMAGE || currentTrainingStep === trainingSteps.UPLOAD_SAMPLE}
                            imageCountInAllClass={imageCountInAllClass}
                            trainedImageCount={trainedImageCount}
                        />
                    </div>
                    <div className={style.trainingResultColumn}>
                        <TrainingResult
                            video={tmTrainingInstance.video}
                            onDeviceChange={onDeviceChange}
                            onApplyModel={onApplyModel}
                            onSaveSample={onSaveSample}
                            tmTrainingInstance={tmTrainingInstance}
                            uploadedSampleLink={uploadedSampleLink}
                            isUploadAvailable={isUploadAvailable}
                            isApplyAvailable={isApplyAvailable}
                            resultContainerRef={this.resultContainerRef}
                        />
                    </div>
                </div>
            </div>
        </Modal>);
    };
}

export default TmTrainingModalComponent;