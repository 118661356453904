exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@property --progress {\n    syntax: '<percentage>';\n    inherits: false;\n    initial-value: 0%;\n}\n  \n.training-button_progress-button_2C_7b {\n    height: 40px;\n    border: none;\n    border-radius: 5px;\n    background: #F1F3F4;\n    font-size: 16px;\n    color: #5F6368;\n    cursor: not-allowed;\n}\n  \n.training-button_progress-button_2C_7b.training-button_blue_ejSEc {\n    border: 1px solid #1F6DD0;\n    background: -webkit-gradient(linear, left top, right top, from(#D2E3FC), to(#E8F1FE));\n    background: linear-gradient(to right, #D2E3FC var(--progress), #E8F1FE var(--progress));\n    transition: --progress 0.5s linear;\n    color: #1F6DD0;\n    cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"progress-button": "training-button_progress-button_2C_7b",
	"progressButton": "training-button_progress-button_2C_7b",
	"blue": "training-button_blue_ejSEc"
};