import React from 'react';
import styles from './training-button.css';
import classNames from 'classnames';

const buttonText = {
    trained: '모델 학습완료됨',
    training: '학습중...',
    train: '모델 학습시키기'
};

const TrainingButton = ({
    isTrainingAvailable,
    isTrained,
    isTraining,
    trainedImageCount,
    imageCountInAllClass,
    onStartTraining
}) => {
    const progress = trainedImageCount / imageCountInAllClass * 100 || 0;
    const text = isTrained ? buttonText.trained :
                isTraining ? buttonText.training : buttonText.train;

    const shouldShowBlueButton = isTrainingAvailable || isTraining;

    return (
        <button
            className={classNames(styles.progressButton, shouldShowBlueButton && styles.blue)}
            disabled={!isTrainingAvailable}
            style={{
                '--progress': `${progress}%`
            }}
            onClick={onStartTraining}
        >
            {text}
        </button>
    );
};

export default TrainingButton;