const COPY_COMMAND = 'copy';

const legacyCopyText = (text) => {
    const tempTextAreaEl = document.createElement('textarea');

    return new Promise((resolve) => {
        tempTextAreaEl.value = text;
        tempTextAreaEl.style.position = 'absolute';
        tempTextAreaEl.style.opacity = '0';
        document.body.appendChild(tempTextAreaEl);
        tempTextAreaEl.select();
        document.execCommand(COPY_COMMAND);

        resolve();
    }).catch(e => {
        console.error(e);
    }).finally(() => {
        tempTextAreaEl.remove();
    });
};

export const copyText = (text) => {
    return navigator.clipboard.writeText(text).catch(() => {
        return legacyCopyText(text);
    });
};
