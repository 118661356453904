import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';

import {connect} from 'react-redux';
import {injectIntl, intlShape} from 'react-intl';

import {updateTargets} from '../reducers/targets';
import {updateBlockDrag} from '../reducers/block-drag';
import {updateMonitors} from '../reducers/monitors';
import {setProjectChanged, setProjectUnchanged} from '../reducers/project-changed';
import {setRunningState, setTurboState, setStartedState} from '../reducers/vm-status';
import {showExtensionAlert} from '../reducers/alerts';
import {setMicActive, setCameraActive} from '../reducers/media-control';
import {updateMicIndicator} from '../reducers/mic-indicator';
import {setVisible, setHidden} from '../reducers/work-loading-state';
import {costumeUpload} from './file-uploader';
import sharedMessages from './shared-messages';
import TmTraining from '../../tm-training/dist/tm-training';
import { FAIL_TYPE, openFailDialog, openSuccessDialog, setMaximumGreenFlagClickCount, setShowAllBlockCategories } from '../reducers/malrang-tutorial';
import { requestMoveTutorial, writeStudyFile } from '../semobae_utils/semobae_utils';
import { openConnectionModal } from '../reducers/modals';
import { setConnectionModalExtensionId } from '../reducers/connection-modal';
import { showStandardAlert } from '../reducers/alerts';
import { PORT_MANAGER_EXTENSION_NAME, DRONE_SIMULATOR_EXTENSION_NAME, requiredPortManagerLinks, requiredDroneSimulatorLinks } from './utils/mapLinkToExtensionId';

/*
 * Higher Order Component to manage events emitted by the VM
 * @param {React.Component} WrappedComponent component to manage VM events for
 * @returns {React.Component} connected component with vm events bound to redux
 */
const vmListenerHOC = function (WrappedComponent) {
    class VMListener extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'handleKeyDown',
                'handleKeyUp',
                'handleProjectChanged',
                'handleTargetsUpdate',
                'onLoading',
                'onLoaded',
                'onAddCostume',
                'onAddBackdrop',
                'makeTmModelInstance',
                'sendSuccessTutorial',
                'sendFailTutorial',
                'handleSemoCodingExtensionLoaded',
                'handleSemoCodingExtensionRemoved',
                'handleOpenUpdateScratchHexModal',
                'handleOpenConnectionModal',
                'onProjectLoaded'
            ]);
            // We have to start listening to the vm here rather than in
            // componentDidMount because the HOC mounts the wrapped component,
            // so the HOC componentDidMount triggers after the wrapped component
            // mounts.
            // If the wrapped component uses the vm in componentDidMount, then
            // we need to start listening before mounting the wrapped component.
            this.props.vm.on('targetsUpdate', this.handleTargetsUpdate);
            this.props.vm.on('MONITORS_UPDATE', this.props.onMonitorsUpdate);
            this.props.vm.on('BLOCK_DRAG_UPDATE', this.props.onBlockDragUpdate);
            this.props.vm.on('TURBO_MODE_ON', this.props.onTurboModeOn);
            this.props.vm.on('TURBO_MODE_OFF', this.props.onTurboModeOff);
            this.props.vm.on('PROJECT_RUN_START', this.props.onProjectRunStart);
            this.props.vm.on('PROJECT_RUN_STOP', this.props.onProjectRunStop);
            this.props.vm.on('PROJECT_CHANGED', this.handleProjectChanged);
            this.props.vm.on('RUNTIME_STARTED', this.props.onRuntimeStarted);
            this.props.vm.on('PROJECT_START', this.props.onGreenFlag);
            this.props.vm.on('PERIPHERAL_CONNECTION_LOST_ERROR', this.props.onShowExtensionAlert);
            this.props.vm.on('MIC_ON', this.props.onMicTurnOn);
            this.props.vm.on('MIC_OFF', this.props.onMicTurnOff);
            this.props.vm.on('MIC_LISTENING', this.props.onMicListeningUpdate);
            this.props.vm.on('CAMERA_ON', this.props.onCameraTurnOn);
            this.props.vm.on('CAMERA_OFF', this.props.onCameraTurnOff);
            this.props.vm.on('WORK_LOADING', this.onLoading);
            this.props.vm.on('WORK_LOADED', this.onLoaded);
            this.props.vm.on('ADD_COSTUME', this.onAddCostume);
            this.props.vm.on('ADD_BACKDROP', this.onAddBackdrop);
            this.props.vm.on('MAKE_TM_MODEL_INSTANCE', this.makeTmModelInstance);
            this.props.vm.on('SUCCESS_TUTORIAL', this.sendSuccessTutorial);
            this.props.vm.on('FAIL_TUTORIAL', () => this.sendFailTutorial(FAIL_TYPE.normalFail));
            this.props.vm.on('FAIL_TUTORIAL_BLOCK_COUNT', () => this.sendFailTutorial(FAIL_TYPE.blockCountFail));
            this.props.vm.on('TUTORIAL_MOVE', (message) => this.sendMoveTutorial(message));
            this.props.vm.on('HANDLE_SEMO_CODING_EXTENSION_LOADED', this.handleSemoCodingExtensionLoaded);
            this.props.vm.on('HANDLE_SEMO_CODING_EXTENSION_REMOVED', this.handleSemoCodingExtensionRemoved);
            this.props.vm.on('OPEN_CONNECTION_MODAL', this.handleOpenConnectionModal);
            this.props.vm.on('PROJECT_LOADED', this.onProjectLoaded);
            this.props.vm.on('OPEN_UPDATE_SCRATCH_HEX_MODAL', this.handleOpenUpdateScratchHexModal);
            this.props.vm.on('HEX_UPDATE_FAILED', () => {
                // TBD: handle hex update failed
            });
            this.props.vm.on('OPEN_UPDATE_SCRATCH_HEX_ALERT', (extensionId) => {
                this.props.onShowAlert(`updateHEX/${extensionId}`);
            })
        }
        componentDidMount () {
            if (this.props.attachKeyboardEvents) {
                document.addEventListener('keydown', this.handleKeyDown);
                document.addEventListener('keyup', this.handleKeyUp);
            }
            this.props.vm.postIOData('userData', {username: this.props.username});
        }
        componentDidUpdate (prevProps) {
            if (prevProps.username !== this.props.username) {
                this.props.vm.postIOData('userData', {username: this.props.username});
            }

            // Re-request a targets update when the shouldUpdateTargets state changes to true
            // i.e. when the editor transitions out of fullscreen/player only modes
            if (this.props.shouldUpdateTargets && !prevProps.shouldUpdateTargets) {
                this.props.vm.emitTargetsUpdate(false /* Emit the event, but do not trigger project change */);
            }
        }
        componentWillUnmount () {
            this.props.vm.removeListener('PERIPHERAL_CONNECTION_LOST_ERROR', this.props.onShowExtensionAlert);
            if (this.props.attachKeyboardEvents) {
                document.removeEventListener('keydown', this.handleKeyDown);
                document.removeEventListener('keyup', this.handleKeyUp);
            }
        }
        handleProjectChanged () {
            if (this.props.shouldUpdateProjectChanged && !this.props.projectChanged) {
                this.props.onProjectChanged();
            }
        }
        handleTargetsUpdate (data) {
            if (this.props.shouldUpdateTargets) {
                this.props.onTargetsUpdate(data);
            }
        }
        handleKeyDown (e) {
            // Don't capture keys intended for Blockly inputs.
            if (e.target !== document && e.target !== document.body) return;

            const key = (!e.key || e.key === 'Dead') ? e.keyCode : e.key;
            this.props.vm.postIOData('keyboard', {
                key: key,
                isDown: true
            });

            // Prevent space/arrow key from scrolling the page.
            if (e.keyCode === 32 || // 32=space
                (e.keyCode >= 37 && e.keyCode <= 40)) { // 37, 38, 39, 40 are arrows
                e.preventDefault();
            }
        }
        handleKeyUp (e) {
            // Always capture up events,
            // even those that have switched to other targets.
            const key = (!e.key || e.key === 'Dead') ? e.keyCode : e.key;
            this.props.vm.postIOData('keyboard', {
                key: key,
                isDown: false
            });

            // E.g., prevent scroll.
            if (e.target !== document && e.target !== document.body) {
                e.preventDefault();
            }
        }
        onLoading (type, message) {
            this.props.onWorkLoading(type, this.props.intl.formatMessage(message));
        }
        onLoaded () {
            this.props.onWorkLoaded();
        }
        onAddCostume (img_b64, targetId) {
            this._addCostume(img_b64, targetId);
        }
        onAddBackdrop (img_b64, targetId) {
            this._addCostume(img_b64, targetId);
        }
        _addCostume (img_b64, targetId) {
            const storage = this.props.vm.runtime.storage;
            const _binaryString = atob(img_b64);
            const _bytes = new Uint8Array(_binaryString.length);
            for (let i = 0; i < _binaryString.length; i++) {
                _bytes[i] = _binaryString.charCodeAt(i);
            }
            const fileData = _bytes.buffer;
            const fileType = 'image/png';
            costumeUpload(fileData, fileType, storage, ([costume]) => {
                if (!costume.name) {
                    const target = this.props.vm.runtime.getTargetById(targetId);
                    const messageType = target.isStage ? 'backdrop' : 'costume';
                    const index = target.getCostumes().length;
                    costume.name = this.props.intl.formatMessage(sharedMessages[messageType], {index});
                }
                this.props.vm.addCostume(costume.md5, costume, targetId);
            });
        }
        makeTmModelInstance (classifierDataset) {
            const tmInstance = new TmTraining();
            tmInstance.init(classifierDataset).then(() => {
                tmInstance.start();
                this.props.vm.runtime.emit('TM_MODEL_INSTANCE_READY', tmInstance);
            });
        }
        sendSuccessTutorial () {
            this.props.openSuccessDialog();
        }
        sendFailTutorial (failType) {
            this.props.openFailDialog(failType);
        }
        sendMoveTutorial (message) {
            if (window.semobaeAPI && this.props.semoclassParams) {

                const semoclassParams = {
                    user: this.props.semoclassParams.user,
                    semoclass: this.props.semoclassParams.semoclass,
                    tutorialTarget: message
                }

                requestMoveTutorial(semoclassParams);
            }
        }
        handleSemoCodingExtensionLoaded(extensionName) {
            if (window.semobaeAPI) {
                writeStudyFile(this.props.saveProjectSb3, this.props.studyFileName).then(() => {
                    const extensionNamesToExecute = [extensionName];
                    if (requiredPortManagerLinks.includes(extensionName)) {
                        extensionNamesToExecute.push(PORT_MANAGER_EXTENSION_NAME);
                    }
                    if (requiredDroneSimulatorLinks.includes(extensionName)) {
                        extensionNamesToExecute.push(DRONE_SIMULATOR_EXTENSION_NAME);
                    }
                    window.semobaeAPI.requestExecuteSemobaeExtension(extensionNamesToExecute);
                });
            }
        }
        handleSemoCodingExtensionRemoved() {
            if (window.semobaeAPI) {
                writeStudyFile(this.props.saveProjectSb3, this.props.studyFileName);
            }
        }
        handleOpenConnectionModal(extensionId) {
            this.props.onOpenConnectionModal(extensionId);
        }
        onProjectLoaded () {
            this.props.updateMaximumGreenFlagClickCount(this.props.vm.runtime.maximumGreenFlagClickCount ?? 0);
            this.props.updateShowAllBlockCategories(!this.props.vm.runtime.filterBlockCategories);

            if (this.props.vm.runtime.shouldRequestLink && window.semobaeAPI) {
                writeStudyFile(this.props.saveProjectSb3, this.props.studyFileName);
                window.semobaeAPI.receiveOnce('wroteStudyFile', (event, args) => {
                    if (args) {
                        let semobaeExtensionNamesToRun = [...this.props.vm.runtime.semoExtensionNames];
                        const hasRequiredPortManagerLinks = semobaeExtensionNamesToRun.some(name => requiredPortManagerLinks.includes(name));
                        const hasRequiredDroneSimulatorLinks = semobaeExtensionNamesToRun.some(name => requiredDroneSimulatorLinks.includes(name));

                        if (hasRequiredPortManagerLinks) {
                            semobaeExtensionNamesToRun.push(PORT_MANAGER_EXTENSION_NAME);
                        }
                        if (hasRequiredDroneSimulatorLinks) {
                            semobaeExtensionNamesToRun.push(DRONE_SIMULATOR_EXTENSION_NAME);
                        }
                        window.semobaeAPI.requestExecuteSemobaeExtension(semobaeExtensionNamesToRun);
                    }
                });
            }
        }
        handleOpenUpdateScratchHexModal (extensionId) {
            this.props.onOpenConnectionModal(extensionId);
        }
        render () {
            const {
                /* eslint-disable no-unused-vars */
                attachKeyboardEvents,
                projectChanged,
                shouldUpdateTargets,
                shouldUpdateProjectChanged,
                onBlockDragUpdate,
                onGreenFlag,
                onKeyDown,
                onKeyUp,
                onMicTurnOn,
                onMicTurnOff,
                onMicListeningUpdate,
                onMonitorsUpdate,
                onTargetsUpdate,
                onProjectChanged,
                onProjectRunStart,
                onProjectRunStop,
                onProjectSaved,
                onRuntimeStarted,
                onTurboModeOff,
                onTurboModeOn,
                onShowExtensionAlert,
                updateMaximumGreenFlagClickCount,
                onOpenConnectionModal,
                openSuccessDialog,
                openFailDialog,
                onWorkLoaded,
                onWorkLoading,
                onShowAlert,
                updateShowAllBlockCategories,
                /* eslint-enable no-unused-vars */
                ...props
            } = this.props;
            return <WrappedComponent {...props} />;
        }
    }
    VMListener.propTypes = {
        intl: intlShape.isRequired,
        attachKeyboardEvents: PropTypes.bool,
        onBlockDragUpdate: PropTypes.func.isRequired,
        onGreenFlag: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        onMicListeningUpdate: PropTypes.func.isRequired,
        onMicTurnOn: PropTypes.func.isRequired,
        onMicTurnOff: PropTypes.func.isRequired,
        onCameraTurnOn: PropTypes.func.isRequired,
        onCameraTurnOff: PropTypes.func.isRequired,
        onMonitorsUpdate: PropTypes.func.isRequired,
        onProjectChanged: PropTypes.func.isRequired,
        onProjectRunStart: PropTypes.func.isRequired,
        onProjectRunStop: PropTypes.func.isRequired,
        onProjectSaved: PropTypes.func.isRequired,
        onRuntimeStarted: PropTypes.func.isRequired,
        onShowExtensionAlert: PropTypes.func.isRequired,
        onTargetsUpdate: PropTypes.func.isRequired,
        onTmModelLoading: PropTypes.func,
        onTmModelLoaded: PropTypes.func,
        onTurboModeOff: PropTypes.func.isRequired,
        onTurboModeOn: PropTypes.func.isRequired,
        projectChanged: PropTypes.bool,
        shouldUpdateTargets: PropTypes.bool,
        shouldUpdateProjectChanged: PropTypes.bool,
        username: PropTypes.string,
        vm: PropTypes.instanceOf(VM).isRequired
    };
    VMListener.defaultProps = {
        attachKeyboardEvents: true,
        onGreenFlag: () => ({})
    };
    const mapStateToProps = state => ({
        projectChanged: state.scratchGui.projectChanged,
        // Do not emit target or project updates in fullscreen or player only mode
        // or when recording sounds (it leads to garbled recordings on low-power machines)
        shouldUpdateTargets: !state.scratchGui.mode.isFullScreen && !state.scratchGui.mode.isPlayerOnly &&
            !state.scratchGui.modals.soundRecorder,
        // Do not update the projectChanged state in fullscreen or player only mode
        shouldUpdateProjectChanged: !state.scratchGui.mode.isFullScreen && !state.scratchGui.mode.isPlayerOnly,
        vm: state.scratchGui.vm,
        username: state.session && state.session.session && state.session.session.user ?
            state.session.session.user.username : '',
        semoclassParams: state.scratchGui.semobaeState.semoclassParams,
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        studyFileName: state.scratchGui.semobaeState.studyFileName,
    });
    const mapDispatchToProps = dispatch => ({
        onTargetsUpdate: data => {
            dispatch(updateTargets(data.targetList, data.editingTarget));
        },
        onMonitorsUpdate: monitorList => {
            dispatch(updateMonitors(monitorList));
        },
        onBlockDragUpdate: areBlocksOverGui => {
            dispatch(updateBlockDrag(areBlocksOverGui));
        },
        onProjectRunStart: () => dispatch(setRunningState(true)),
        onProjectRunStop: () => dispatch(setRunningState(false)),
        onProjectChanged: () => dispatch(setProjectChanged()),
        onProjectSaved: () => dispatch(setProjectUnchanged()),
        onRuntimeStarted: () => dispatch(setStartedState(true)),
        onTurboModeOn: () => dispatch(setTurboState(true)),
        onTurboModeOff: () => dispatch(setTurboState(false)),
        onShowExtensionAlert: data => {
            dispatch(showExtensionAlert(data));
        },
        onMicTurnOn: () => {
            dispatch(setMicActive(true));
        },
        onMicTurnOff: () => {
            dispatch(setMicActive(false));
        },
        onMicListeningUpdate: listening => {
            dispatch(updateMicIndicator(listening));
        },
        onCameraTurnOn: () => {
            dispatch(setCameraActive(true));
        },
        onCameraTurnOff: () => {
            dispatch(setCameraActive(false));
        },
        onWorkLoading: (workType, message) => {
            dispatch(setVisible(workType, message));
        },
        onWorkLoaded: () => {
            dispatch(setHidden());
        },
        openSuccessDialog: () => dispatch(openSuccessDialog()),
        openFailDialog: (failtype) => dispatch(openFailDialog(failtype)),
        onOpenConnectionModal: id => {
            dispatch(setConnectionModalExtensionId(id));
            dispatch(openConnectionModal());
        },
        updateMaximumGreenFlagClickCount: maximumGreenFlagClickCount => {
            dispatch(setMaximumGreenFlagClickCount(maximumGreenFlagClickCount));
        },
        updateShowAllBlockCategories: showAllBlockCategories => {
            dispatch(setShowAllBlockCategories(showAllBlockCategories));
        },
        onShowAlert: alertType => {
            dispatch(showStandardAlert(alertType));
        }
    });
    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps
    )(VMListener));
};

export default vmListenerHOC;
