export const SEMOBAE_USER_TYPE = {
    TEACHER: 1,
    STUDENT: 2,
    SCHOOL_MANAGER: 3,
    CONTENT_CREATOR: 4,
    SYSTEM_OPERATOR: 5,
};

export function writeStudyFile (saveProjectSb3, fileName) {
    if (!window.semobaeAPI) {
        return Promise.resolve();
    }
    return saveProjectSb3().then(blob => blob.arrayBuffer())
        .then(arrayBuffer => {
            console.log('write study file : ', arrayBuffer);
            window.semobaeAPI.writeStudyFile(fileName, arrayBuffer);
        });
}

export function getStudyFile (fileName, codingTemplateUrl) {
    if (!window.semobaeAPI) {
        return new Promise(resolve => resolve);
    }

    return new Promise(resolve => {
        window.semobaeAPI.receiveOnce('studyFile', (event, arrayBuffer) => {
            console.log('study file : ', arrayBuffer);
            if (arrayBuffer && arrayBuffer.byteLength > 1000) {
                const file = new Blob([arrayBuffer]);
                resolve(file);
            } else {
                resolve(null);
            }
        });
        window.semobaeAPI.readStudyFile(fileName);
    });
}

export function getFile (dataURL) {
    if (!window.semobaeAPI || !dataURL) {
        console.log('failed to read file:', dataURL);
        return Promise.resolve();
    }
    return new Promise(resolve => {
        window.semobaeAPI.receiveOnce('loadFile', (event, arrayBuffer) => {
            const file = new Blob([arrayBuffer]);
            resolve(file);
        });
        window.semobaeAPI.send('loadFile', dataURL);
    });
}

export function getCodingTemplateFile (url) {
    if (!window.semobaeAPI || !url) {
        return Promise.resolve();
    }
    return fetchFile(url);
}

function fetchFile (url) {
    return fetch(url).then(response => response.blob());
}

export function createHistoryFile (saveProjectSb3) {
    if (!window.semobaeAPI) {
        return Promise.resolve();
    }
    return saveProjectSb3().then(blob => blob.arrayBuffer())
        .then(arraybuffer => {
            window.semobaeAPI.send('createHistory', arraybuffer);
        });
}

export function uploadExtraFile (fileData, url) {
    if (!window.semobaeAPI) {
        return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
        window.semobaeAPI.receiveOnce('fileURL', (event, result) => {
            if (!result) {
                return reject(result);
            }

            resolve(result);
        });
        window.semobaeAPI.send('saveFile', 'file', JSON.stringify(fileData), url);
    })
}

export function sendTutorialOutcomes(isSuccess, semoclassParams) {
    if (!window.semobaeAPI) {
        return Promise.reject();
    }
    return new Promise(resolve => {
        window.semobaeAPI.receiveOnce('tutorialOutcomes', (event, result) => {
            resolve(result);
        });
        window.semobaeAPI.send('tutorialOutcomes', {
            ...semoclassParams,
            isSuccess
        });
    });
}

export function requestTutorialSb3File({ user, semoclass }) {
    if (!window.semobaeAPI) {
        return Promise.reject();
    }
    window.semobaeAPI.send('requestCodingTemplateURL', { user, semoclass });
}

export function showMalrangTutorialHelpDialog() {
    if (!window.semobaeAPI) {
        return;
    }
    // TODO: https://pocl.atlassian.net/browse/POCL-12380
    // TODO: https://pocl.atlassian.net/browse/POCL-12381
    window.semobaeAPI.send('showMalrangTutorialHelpDialog');
}

export function showMalrangClassLoading() {
    if (!window.semobaeAPI) {
        return;
    }
    window.semobaeAPI.send('showMalrangClassLoading', true);
}

export function hideMalrangClassLoading() {
    if (!window.semobaeAPI) {
        return;
    }
    window.semobaeAPI.send('showMalrangClassLoading', false);
}

export function requestMoveTutorial(semoclassParams) {
    if (!window.semobaeAPI) {
        return;
    }

    const { user, semoclass, tutorialTarget } = semoclassParams;

    if(!user || !semoclass || !tutorialTarget) {
        console.error('Invalid semoclassParams:', semoclassParams);
        return ;
    }

    if (typeof tutorialTarget !== 'string') {
        console.error('Invalid tutorial target:', tutorialTarget);
        return;
    }

    if (/^\d{1,}-\d{1,}$/.test(tutorialTarget) === false) {
        console.error('Invalid tutorial target. target must be Number-Number, but got:', tutorialTarget);
        return;
    }

    window.semobaeAPI.send('requestTargetCodingTemplateUrl', semoclassParams);
}

export const SEMOBAE_DATA_TRANSFER_CHANNEL = {
    SERIAL_PORT_INFO: 'SERIAL_PORT_INFO',
    CLEAR_RECEIVE_CHECK_INTERVAL: 'clearReceiveCheckInterval',
    UPDATE_SCRATCH_HEX: 'update scratch hex',
}

export const SEMOBAE_EXTENSION_ID = {
    codingRider: '@semobae-ex/byrobot-drone-link',
    codingDrone: '@semobae-ex/coding-drone-link',
    malrang: '@semobae-ex/malrang-link',
    nemo: '@semobae-ex/nemo-link',
    technic: '@semobae-ex/probo-technic-link',
    skykick: '@semobae-ex/skykick-link',
    markCoding: '@semobae-ex/mc-link',
};

export const SEMOBAE_DATA_TRANSFER_DESTINATION = {
    WEBVIEW: 'webview',
    WORKER: 'worker',
};

const SEMOBAE_DATA_TRANSFER_RECEIVE_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
}; 

/**
 * @param {string} channel
 * @param {string} targetExtension
 * @param {'webview' | 'worker'} destination
 * @param {object} data
 * @returns {Promise}
 */
export function requestToSemobaeExtension(channel, targetExtension, destination, data) {
    if (!window.semobaeAPI || !channel || !targetExtension) {
        return Promise.reject();
    }

    return new Promise((resolve, reject) => {
        window.semobaeAPI.receiveOnce(channel, (event, receiveData) => {
            if (receiveData.type === SEMOBAE_DATA_TRANSFER_RECEIVE_TYPE.ERROR) {
                return reject(receiveData.value);
            }
            resolve(receiveData.data);
        });

        window.semobaeAPI.send('INTER_EXTENSION_DATA_TRANSFER', targetExtension, destination, {
            channel,
            data,
        });
    });
}

export const downloadExtraFile = getFile;
