import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import VM from 'scratch-vm';

import Box from '../box/box.jsx';
import {STAGE_DISPLAY_SIZES} from '../../lib/layout-constants.js';
import {getStageDimensions} from '../../lib/screen-utils.js';
import StageHeader from '../../containers/stage-header.jsx';
import Stage from '../../containers/stage.jsx';
import Loader from '../loader/loader.jsx';

import styles from './stage-wrapper.css';
const droneSimulatorUrl = 'http://localhost:5000';

const StageWrapperComponent = function (props) {
    const {
        isFullScreen,
        isRtl,
        isRendererSupported,
        loading,
        stageSize,
        vm,
        onStartLoadingTemplateFile,
        isSimulatorMode
    } = props;

    const stageDimensions = getStageDimensions(stageSize, isFullScreen);

    return (
        <Box
            className={classNames(
                styles.stageWrapper,
                {[styles.fullScreen]: isFullScreen}
            )}
            dir={isRtl ? 'rtl' : 'ltr'}
        >
            <Box className={styles.stageMenuWrapper}>
                <StageHeader
                    stageSize={stageSize}
                    vm={vm}
                />
            </Box>
            <Box className={styles.stageCanvasWrapper}>
                <iframe
                    src={droneSimulatorUrl}
                    style={{
                        height: stageDimensions.height,
                        width: stageDimensions.width,
                        display: isSimulatorMode ? 'block' : 'none'
                    }}
                />
                {!isSimulatorMode && isRendererSupported &&
                    <Stage
                        stageSize={stageSize}
                        vm={vm}
                        onStartLoadingTemplateFile={onStartLoadingTemplateFile}
                    />
                }
            </Box>
            {loading ? (
                <Loader isFullScreen={isFullScreen} />
            ) : null}
        </Box>
    );
};

StageWrapperComponent.propTypes = {
    isFullScreen: PropTypes.bool,
    isRendererSupported: PropTypes.bool.isRequired,
    isSimulatorMode: PropTypes.bool,
    isRtl: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    onStartLoadingTemplateFile: PropTypes.func
};

export default StageWrapperComponent;
