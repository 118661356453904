const SET_ID = 'scratch-gui/connection-modal/setId';
const SET_UPDATE_HEX = 'scratch-gui/connection-modal/setUpdateHex';
const SET_UPDATE_FIRMWARE = 'scratch-gui/connection-modal/setUpdateFirmware';

const initialState = {
    extensionId: null,
    updateHex: false,
    updateFirmware: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_ID:
        return Object.assign({}, state, {
            extensionId: action.extensionId
        });
    case SET_UPDATE_HEX:
        return Object.assign({}, state, {
            updateHex: action.updateHex
        });
    case SET_UPDATE_FIRMWARE:
        return Object.assign({}, state, {
            updateFirmware: action.updateFirmware
        });
    default:
        return state;
    }
};

const setConnectionModalExtensionId = function (extensionId) {
    return {
        type: SET_ID,
        extensionId: extensionId
    };
};

const setUpdateHex = function (updateHex) {
    return {
        type: SET_UPDATE_HEX,
        updateHex: updateHex
    };
}

const setUpdateFirmware = function (updateFirmware) {
    return {
        type: SET_UPDATE_FIRMWARE,
        updateFirmware: updateFirmware
    };
}

export {
    reducer as default,
    initialState as connectionModalInitialState,
    setConnectionModalExtensionId,
    setUpdateHex,
    setUpdateFirmware
};
