import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../box/box.jsx';
import Dots from './dots.jsx';
import bluetoothIcon from './icons/bluetooth-white.svg';
import nemoIcon from './icons/icon_nemo.svg';
import styles from './connection-modal.css';
import classNames from 'classnames';

const bluetoothIcons = {
    nemo: nemoIcon
};

const excludedBluetoothExtensionIds = [
    'markCoding',
    'technic',
    'skykick',
    'codingDrone',
    'codingRider'
];

const ConnectedStep = props => (
    <Box className={styles.body}>
        <Box className={styles.activityArea}>
            <Box className={styles.centeredRow}>
                <div className={styles.peripheralActivity}>
                    <img
                        className={styles.peripheralActivityIcon}
                        src={props.connectionIconURL}
                    />
                    {props.title === 'codingRider' && (
                        <div className={styles.droneModeText}>
                            <FormattedMessage
                                defaultMessage="you can switch to simulator mode or drone mode by clicking the 'drone mode' or 'simulator mode' block on the palette."
                                description="guide for drone mode change"
                                id="gui.connection.drone-mode-guide"
                            />
                        </div>
                    )}
                    {!excludedBluetoothExtensionIds.includes(props.title) && <img
                        className={classNames(styles.bluetoothConnectedIcon, styles[`${props.title}-bluetooth-connected-icon`])}
                        src={bluetoothIcons[props.title] ? bluetoothIcons[props.title] : bluetoothIcon}
                    />}
                </div>
            </Box>
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                {(props.title !== 'nemo' && props.title !== 'markCoding') && (
                    <FormattedMessage
                        defaultMessage="Connected"
                        description="Message indicating that a device was connected"
                        id="gui.connection.connected"
                    />
                )}
                {props.title === 'markCoding' && (
                    <span>마크링크와 성공적으로 연결되었습니다.</span>
                )}
                {props.title === 'nemo' && (
                    <FormattedMessage
                        defaultMessage="Connected"
                        description="Message indicating that a device was connected"
                        id="gui.connection.nemo.connected"
                    />
                )}
            </Box>
            {props.title !== 'nemo' && (
                <Dots
                    success
                    className={styles.bottomAreaItem}
                    total={3}
                />
            )}
            <div className={classNames(styles.bottomAreaItem, styles.cornerButtons)}>
                <button
                    className={classNames(styles.bgDisconnect, styles.connectionButton)}
                    onClick={props.onDisconnect}
                >
                    <FormattedMessage
                        defaultMessage="Disconnect"
                        description="Button to disconnect the device"
                        id="gui.connection.disconnect"
                    />
                </button>
                {props.title === 'nemo' && (
                    <button
                        className={classNames(styles.bgFirmwareUpdate, styles.connectionButton)}
                        onClick={props.handleOpenScratchHexModal}
                    >
                        펌웨어 업데이트
                    </button>
                )}
                {
                    props.title === 'newmalrang' && (
                        <button
                            className={classNames(styles.bgFirmwareUpdate, styles.connectionButton)}
                            onClick={props.handleOpenFirmwareModal}
                        >
                            펌웨어 업데이트
                        </button>
                    )
                }
                {props.title === 'codingRider' ? (
                    <div className={styles.droneModeButtons}>
                        <button
                            className={classNames(styles.bgSimulatorModeButton, styles.connectionButton)}
                            onClick={props.handleSetSimulatorMode}
                        >
                            <FormattedMessage
                                defaultMessage="simulator mode"
                                description="Button to switch to simulator mode"
                                id="gui.connection.simulator-mode"
                            />
                        </button>
                        <button
                            className={classNames(styles.bgDroneModeButton, styles.connectionButton)}
                            onClick={props.handleSetDroneMode}
                        >
                            <FormattedMessage
                                defaultMessage="drone mode"
                                description="Button to switch to drone mode"
                                id="gui.connection.drone-mode"
                            />
                        </button>
                    </div>
                ) : (
                        <button
                            className={styles.connectionButton}
                            onClick={props.onCancel}
                        >
                            <FormattedMessage
                                defaultMessage="Go to Editor"
                                description="Button to return to the editor"
                                id="gui.connection.go-to-editor"
                            />
                        </button>
                    )
                }
            </div>
        </Box>
    </Box>
);

ConnectedStep.propTypes = {
    connectionIconURL: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    onDisconnect: PropTypes.func,
    title: PropTypes.string
};

export default ConnectedStep;
