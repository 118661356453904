/**
 * Maximizes the flyout height.
 * CAUTION: This function must be called after ScratchBlocks.VerticalFlyout.prototype.position, otherwise it will have no effect.
 * 
 * @param {HTMLDivElement} content The div element to fit the flyout height
 * @param {SVGElement} flyout The flyout element to maximize in height
 * @param {HTMLDivElement} toolbox The toolbox element to maximize in height
 */
export const maximizeFlyoutHeight = (content, flyout, toolbox) => {
    if (content === null || flyout === null || toolbox === null) {
        return;
    }

    const path = flyout.querySelector('.blocklyFlyoutBackground');
    
    const modalContentHeight = content.offsetHeight;
    const d = path.getAttribute('d');

    path.setAttribute('d', d.replace(/v\s*\d+/, `v ${modalContentHeight}`));
    flyout.setAttribute('height', `${modalContentHeight}`);
    toolbox.setAttribute('style', `height: ${modalContentHeight}px !important;`);
};