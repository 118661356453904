exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".training-status-section_width-full_34hrd {\n    width: 100%;\n}\n\n.training-status-section_traning-status-section_1nYsK {\n    width: 240px;\n}\n\n.training-status-section_section-content_3_v_A {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: column;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    width: 240px;\n    gap: 24px;\n    padding: 20px;\n}\n\n.training-status-section_status-title_1vtE3 {\n    font: 400 20px Inter;\n    color: #000;\n}\n\n.training-status-section_timer-container_1m-OH {\n    color: #1F6DD0;\n    font-size: 16px;\n    line-height: 19.36px;\n}", ""]);

// exports
exports.locals = {
	"width-full": "training-status-section_width-full_34hrd",
	"widthFull": "training-status-section_width-full_34hrd",
	"traning-status-section": "training-status-section_traning-status-section_1nYsK",
	"traningStatusSection": "training-status-section_traning-status-section_1nYsK",
	"section-content": "training-status-section_section-content_3_v_A",
	"sectionContent": "training-status-section_section-content_3_v_A",
	"status-title": "training-status-section_status-title_1vtE3",
	"statusTitle": "training-status-section_status-title_1vtE3",
	"timer-container": "training-status-section_timer-container_1m-OH",
	"timerContainer": "training-status-section_timer-container_1m-OH"
};