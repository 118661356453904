import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {connect} from 'react-redux';

import ControlsComponent from '../components/controls/controls.jsx';
import { setMicActive, setIsMicConnected, setIsCameraConnected, setCameraActive } from '../reducers/media-control.js';
import { closeFailDialog, closeSuccessDialog, FAIL_TYPE } from '../reducers/malrang-tutorial.js';

class Controls extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleGreenFlagClick',
            'handleStopAllClick',
            'handleCameraClick',
            'handleMicClick',
            'handleExtensionAdded'
        ]);
    }

    componentDidMount () {
        this.props.vm.addListener('EXTENSION_ADDED', this.handleExtensionAdded);
        if (this.props.vm.runtime.ioDevices.audio.isEnable) {
            this.props.onMicConnected();
        }
    }
    componentWillUnmount () {
        this.props.vm.removeListener('EXTENSION_ADDED', this.handleExtensionAdded);
    }

    handleExtensionAdded (extension) {
        if (extension.id === 'videoSensing') {
            const detectInterval = setInterval(() => {
                if (this.props.vm.runtime.ioDevices.video.videoReady){
                    this.props.onCameraConnected();
                    this.props.setCameraOn();
                    clearInterval(detectInterval);
                }
            }, 300);
        }

        if (extension.id === 'recognition' || extension.id === 'teachableMachine') {
            this.props.vm.runtime.ioDevices.audio.enableAudio();
            const detectInterval = setInterval(() => {
                if (this.props.vm.runtime.ioDevices.audio.isEnable){
                    this.props.onMicConnected();
                    clearInterval(detectInterval);
                }
            }, 300);
        }
    }

    handleGreenFlagClick (e) {
        e.preventDefault();
        if (e.shiftKey) {
            this.props.vm.setTurboMode(!this.props.turbo);
        } else {
            this.clearTutorialDialog();
            if (!this.props.isStarted) {
                this.props.vm.start();
            }
            this.props.vm.greenFlag();
        }
    }

    handleStopAllClick (e) {
        e.preventDefault();
        this.clearTutorialDialog();
        this.props.vm.stopAll();
    }

    handleCameraClick (e) {
        e.preventDefault();
        if (this.props.cameraActive) {
            this.props.vm.runtime.ioDevices.video.disableVideo();
        } else {
            this.props.vm.runtime.ioDevices.video.enableVideo();
        }
        this.props.cameraActive ? this.props.setCameraOff() : this.props.setCameraOn();
    }

    handleMicClick (e) {
        e.preventDefault();
        if (this.props.micActive) {
            this.props.vm.runtime.ioDevices.audio.disableAudio();
        } else {
            this.props.vm.runtime.ioDevices.audio.enableAudio();
        }
    }

    clearTutorialDialog() {
        if (this.props.openSuccessDialog) {
            this.props.closeSuccessDialog();
        }
        if (this.props.openFailDialog.isOpen) {
            this.props.closeFailDialog();
        }
    }

    render () {
        const {
            vm, // eslint-disable-line no-unused-vars
            isStarted, // eslint-disable-line no-unused-vars
            projectRunning,
            turbo,
            micActive,
            cameraActive,
            isMicConnected,
            setMicOn,
            setMicOff,
            onMicConnected,
            isCameraConnected,
            setCameraOn,
            setCameraOff,
            onCameraConnected,
            ...props
        } = this.props;
        
        return (
            <ControlsComponent
                {...props}
                active={projectRunning}
                turbo={turbo}
                onGreenFlagClick={this.handleGreenFlagClick}
                onStopAllClick={this.handleStopAllClick}
                onCameraClick={this.handleCameraClick}
                onMicClick={this.handleMicClick}
                isCameraConnected={isCameraConnected}
                isCameraOn={cameraActive}
                isMicConnected={isMicConnected}
                isMicOn={micActive}
            />
        );
    }
}

Controls.propTypes = {
    isStarted: PropTypes.bool.isRequired,
    projectRunning: PropTypes.bool.isRequired,
    turbo: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM),
    micActive: PropTypes.bool.isRequired,
    cameraActive: PropTypes.bool.isRequired,
    setMicOn: PropTypes.func.isRequired,
    setMicOff: PropTypes.func.isRequired,
    setCameraOn: PropTypes.func.isRequired,
    setCameraOff: PropTypes.func.isRequired,
    openSuccessDialog: PropTypes.bool,
    openFailDialog: PropTypes.shape({
        isOpen: PropTypes.bool.isRequired,
        type: PropTypes.oneOf(Object.values(FAIL_TYPE)),
    }).isRequired,
};

const mapStateToProps = state => ({
    isStarted: state.scratchGui.vmStatus.running,
    projectRunning: state.scratchGui.vmStatus.running,
    turbo: state.scratchGui.vmStatus.turbo,
    micActive: state.scratchGui.mediaActive.micActive,
    cameraActive: state.scratchGui.mediaActive.cameraActive,
    isMicConnected: state.scratchGui.mediaActive.isMicConnected,
    isCameraConnected: state.scratchGui.mediaActive.isCameraConnected,
    openSuccessDialog: state.scratchGui.malrangTutorialState.openSuccessDialog,
    openFailDialog: state.scratchGui.malrangTutorialState.openFailDialog,
});
// no-op function to prevent dispatch prop being passed to component
const mapDispatchToProps = dispatch => ({
    setMicOn: () => dispatch(setMicActive(true)),
    setMicOff: () => dispatch(setMicActive(false)),
    onMicConnected: () => dispatch(setIsMicConnected(true)),
    closeSuccessDialog: () => dispatch(closeSuccessDialog()),
    closeFailDialog: () => dispatch(closeFailDialog()),
    setCameraOn: () => dispatch(setCameraActive(true)),
    setCameraOff: () => dispatch(setCameraActive(false)),
    onCameraConnected: () => dispatch(setIsCameraConnected(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
