import React from 'react';
import NewmalrangUpdateFirmware from './newmalrang-update-firmware.jsx';

const UpdateFirmwareStep = ({ updateFirmware, handleFirmwareUpdated, vm, extensionId, onScanning, onCancel, onConnected }) => {
    
    const extensionIdToModal = {
        'newmalrang': <NewmalrangUpdateFirmware
            extensionId={extensionId}
            handleFirmwareUpdated={handleFirmwareUpdated}
            updateFirmware={updateFirmware}
            onScanning={onScanning}
            vm={vm}
        />
    };
    
    return extensionIdToModal[extensionId];
}

export default UpdateFirmwareStep;
