import classNames from 'classnames';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {FormattedMessage, injectIntl, intlShape, defineMessages} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';
import LanguageSelector from '../../containers/language-selector.jsx';
import VM from 'scratch-vm';
import {openMalrangTutorialModal, openTipsLibrary} from '../../reducers/modals';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openSemobaeFileMenu,
    closeSemobaeFileMenu,
    semobaeFileMenuOpen,
    editMenuOpen,
    openEditMenu,
    closeEditMenu,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen
} from '../../reducers/menus';
import {
    isToolboxEditable,
    startEditToolbox,
    stopEditToolbox
} from '../../reducers/toolbox';
import collectMetadata from '../../lib/collect-metadata';
import sharedMessages from '../../lib/shared-messages';
import styles from './semobae-menubar.css';
import folderIconClosed from './icon-folder-blue.svg';
import folderIconOpened from './icon-folder-white.svg';
import pencilIconClosed from './icon-pencil-blue.svg';
import pencilIconOpened from './icon-pencil-white.svg';
import TurboMode from '../../containers/turbo-mode.jsx';
import languageIconClosed from './language-icon-blue.svg';
import languageIconOpened from './language-icon-white.svg';
import dropdownCaretClosed from './dropdown-caret-blue.svg';
import dropdownCaretOpened from './dropdown-caret-white.svg';
import { SemoConstants } from '../../lib/constant/index.js';
import { notScratchDesktop } from '../../lib/isScratchDesktop';
import { closeFailDialog, closeSuccessDialog } from '../../reducers/malrang-tutorial.js';
import { IS_ANDROID } from '../../lib/utils/android.js';

const ariaMessages = defineMessages({
    language: {
        id: 'gui.menuBar.LanguageSelector',
        defaultMessage: 'language selector',
        description: 'accessibility text for the language selection menu'
    }
});

const MenuItem = ({
    children,
    className,
    onClick
}) => (
    <li
        className={classNames(
            styles.menuItem,
            styles.hoverable,
            className
        )}
        onClick={onClick}
    >
        {children}
    </li>
);

MenuItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func
};

class SemobaeMenubarComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickNew',
            'handleClickLoadFromCloud',
            'getSaveToComputerHandler',
            'toggleMenu',
            'toggleLanguageMenu',
            'onStartSelectingFileUpload',
            'handleClickEditMenu',
            'handleClickTemplateReload',
            'handleToggleEditToolbox'
        ]);
    }

    getSaveToComputerHandler (downloadProjectCallback) {
        return () => {
            this.props.onRequestCloseFile();
            downloadProjectCallback();
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
                this.props.onProjectTelemetryEvent('projectDidSave', metadata);
            }
        };
    }

    onStartSelectingFileUpload () {
        this.props.onStartSelectingFileUpload();
        this.props.onRequestCloseFile();
    }

    handleClickNew () {
        // if the project is dirty, and user owns the project, we will autosave.
        // but if they are not logged in and can't save, user should consider
        // downloading or logging in first.
        // Note that if user is logged in and editing someone else's project,
        // they'll lose their work.
        this.props.onRequestCloseFile();
        this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
        this.props.closeSuccessDialog();
        this.props.closeFailDialog();
    }

    handleClickTemplateReload () {
        const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
            this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
        );
        this.props.onRequestCloseFile();
        if (readyToReplaceProject) {
            const hasTemplate = !!this.props.codingTemplateUrl;
            if (hasTemplate) {
                this.props.onStartLoadingTemplateFile();
            } else {
                this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
            }
        }
        this.props.onRequestCloseFile();
    }

    handleClickLoadFromCloud () {
        this.props.onStartLoadingStudyFileFromCloud();
        this.props.onRequestCloseFile();
    }

    toggleMenu () {
        if (this.props.fileMenuOpen) {
            this.props.onRequestCloseFile();
        } else {
            this.props.onClickFile();
        }
    }

    toggleLanguageMenu (e) {
        if (this.props.languageMenuOpen) {
            this.props.onRequestCloseLanguage();
            document.removeEventListener('mouseup', this.toggleLanguageMenu);
        } else {
            this.props.onMouseUpLanguage(e);
            document.addEventListener('mouseup', this.toggleLanguageMenu);
        }
    }

    handleClickEditMenu () {
        if (this.props.isEditMenuOpen) {
            this.props.onCloseEdit();
        } else {
            this.props.onOpenEdit();
        }
    }

    handleToggleEditToolbox () {
        if (this.props.isToolboxEditable) {
            this.props.onStopEditToolbox();
        } else {
            this.props.onStartEditToolbox();
        }
    }

    render () {
        const opened = this.props.fileMenuOpen;
        const isLanguageOpen = this.props.languageMenuOpen;
        const isEditOpen = this.props.isEditMenuOpen;
        const folderIcon = opened ? folderIconOpened : folderIconClosed;
        const pencilIcon = isEditOpen ? pencilIconOpened : pencilIconClosed;
        const languageIcon = isLanguageOpen ? languageIconOpened : languageIconClosed;
        const dropdownCaret = isLanguageOpen ? dropdownCaretOpened : dropdownCaretClosed;
        const {isToolboxEditable} = this.props;

        return (
            <div className={styles.wrapper}>
                {(this.props.canChangeLanguage) &&
                    <div className={classNames(styles.fileMenu, styles.languageFileMenu)}>
                        <div
                            className={classNames(styles.menuOpener, styles.languageOpener, {[styles.opened]: isLanguageOpen})}
                            onMouseUp={this.toggleLanguageMenu}
                        >
                            <div className={styles.menuItemIconGroup}>
                                <img
                                    className={styles.menuItemIcon}
                                    src={languageIcon}
                                />
                                <img
                                className={styles.menuItemIcon}
                                    src={dropdownCaret}
                                />
                            </div>
                            <LanguageSelector label={this.props.intl.formatMessage(ariaMessages.language)} />
                        </div>
                    </div>
                }

                {
                    !this.props.isFileMenuHidden && (
                        <div className={styles.fileMenu}>
                            <div
                                className={classNames(styles.menuOpener, {[styles.opened]: opened})}
                                onClick={this.toggleMenu}
                            >
                                <div className={styles.menuItemIconGroup}>
                                    <img
                                        src={folderIcon}
                                        className={styles.menuItemIcon}
                                    />
                                    <FormattedMessage
                                        defaultMessage="File"
                                        description="Text for file dropdown menu"
                                        id="gui.menuBar.file"
                                    />
                                </div>
                                <div className={classNames(styles.menuDropdown, {[styles.opened]: opened})}>
                                    <div 
                                        className={styles.menuDropdownBackground} 
                                        onClick={this.toggleMenu}
                                    />
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleClickNew}
                                    >
                                        <FormattedMessage
                                            defaultMessage="New"
                                            description="Menu bar item for creating a new project"
                                            id="gui.menuBar.new"
                                        />
                                    </MenuItem>
                                    {notScratchDesktop() && !this.props.isMissionMode && !IS_ANDROID &&
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={this.handleClickTemplateReload}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Reload Template"
                                                description="Menu bar item for reloading a template project"
                                                id="gui.menuBar.reloadTemplate"
                                            />
                                        </MenuItem>}
                                    {notScratchDesktop() && !this.props.isMissionMode && !IS_ANDROID &&
                                        <MenuItem
                                            onClick={this.props.onOpenMalrangTutorialModal}
                                        >
                                            {this.props.intl.formatMessage(sharedMessages.persistTutorialConfigTitle)}
                                        </MenuItem>}
                                    {notScratchDesktop() && !this.props.isMissionMode && !IS_ANDROID &&
                                        <MenuItem
                                            onClick={this.handleClickLoadFromCloud}
                                        >
                                            {this.props.intl.formatMessage(sharedMessages.loadFromCloudStorageTitle)}
                                            <div className={styles.tooltip}>
                                                <FormattedMessage
                                                    defaultMessage="load From cloud storage"
                                                    description="load project to Semobae cloud storage"
                                                    id="gui.menuBar.loadFromCloudStorageTitleTooltip"
                                                />
                                            </div>
                                        </MenuItem>}
                                    {notScratchDesktop() && !this.props.isMissionMode && !IS_ANDROID &&
                                        <SB3Downloader>{(className, downloadProjectCallback, saveProjectToCloud, saveProjectToSemobaeHistory) => (
                                            <MenuItem onClick={this.getSaveToComputerHandler(saveProjectToSemobaeHistory)}>
                                                <FormattedMessage
                                                    defaultMessage="Save to logbook"
                                                    description="save a project to Logbook"
                                                    id="gui.menuBar.writeToLogbook"
                                                />
                                            </MenuItem>
                                        )}</SB3Downloader>}
                                    {notScratchDesktop() && !this.props.isMissionMode && !IS_ANDROID &&
                                        <SB3Downloader>{(className, downloadProjectCallback, saveProjectToCloud) => (
                                            <MenuItem
                                                className={className}
                                                onClick={this.getSaveToComputerHandler(saveProjectToCloud)}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Save to cloud storage"
                                                    description="Menu bar item for saving a project to Semobae cloud storage" // eslint-disable-line max-len
                                                    id="gui.menuBar.writeToCloudStorage"
                                                />
                                                <div className={styles.tooltip}>
                                                    <FormattedMessage
                                                        defaultMessage="Save to cloud storage"
                                                        description="saving a project to Semobae cloud storage"
                                                        id="gui.menuBar.writeToCloudStorageTooltip"
                                                    />
                                                </div>
                                            </MenuItem>
                                        )}</SB3Downloader>}
                                    {!IS_ANDROID &&
                                    <>
                                        <MenuItem
                                            onClick={this.onStartSelectingFileUpload}
                                        >
                                            {this.props.intl.formatMessage(sharedMessages.loadFromComputerTitle)}
                                        </MenuItem>
                                        <SB3Downloader>{(className, downloadProjectCallback,) => (
                                            <MenuItem
                                                className={className}
                                                onClick={this.getSaveToComputerHandler(downloadProjectCallback)}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Save to computer"
                                                    description="Menu bar item for saving a project to computer" // eslint-disable-line max-len
                                                    id="gui.menuBar.downloadToComputer"
                                                />
                                            </MenuItem>
                                    )}</SB3Downloader>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    !this.props.isEditMenuHidden && (
                        <div className={styles.fileMenu}>
                            <div
                                className={classNames(styles.menuOpener, {[styles.opened]: isEditOpen})}
                                onClick={this.handleClickEditMenu}
                            >
                                <div className={styles.menuItemIconGroup}>
                                    <img
                                        src={pencilIcon}
                                        className={styles.menuItemIcon}
                                    />
                                    <FormattedMessage
                                        defaultMessage="Edit"
                                        id="gui.semobae.menu.edit"
                                    />
                                </div>
                                <div className={classNames(styles.menuDropdown, styles.editMenuDropdown, {[styles.opened]: isEditOpen})}>
                                    <div 
                                        className={styles.menuDropdownBackground} 
                                        onClick={this.handleClickEditMenu}
                                    />
                                    <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={toggleTurboMode}
                                        >
                                            {turboMode ? <FormattedMessage
                                                defaultMessage="Turn off Turbo Mode"
                                                id="gui.semobae.menuItem.edit.turbo.off"
                                            /> : <FormattedMessage
                                                defaultMessage="Turn on Turbo Mode"
                                                id="gui.semobae.menuItem.edit.turbo.on"
                                            />}
                                        </MenuItem>
                                    )}</TurboMode>
                                    {
                                        !this.props.isMissionMode && (
                                            <MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={this.handleToggleEditToolbox}
                                            >
                                                <div>
                                                    {isToolboxEditable ? <FormattedMessage
                                                        defaultMessage="edit toolbox off"
                                                        id="gui.semobae.menuItem.edit.toolbox.off"
                                                    /> : <FormattedMessage
                                                        defaultMessage="edit toolbox on"
                                                        id="gui.semobae.menuItem.edit.toolbox.on"
                                                    />}
                                                </div>
                                            </MenuItem>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

SemobaeMenubarComponent.propTypes = {
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    onClickNew: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onStartLoadingStudyFileFromCloud: PropTypes.func,
    onStartLoadingTemplateFile: PropTypes.func,
    sessionExists: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    canChangeLanguage: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    const toolboxState = state.scratchGui.toolbox;
    return {
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        locale: state.locales.locale,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        fileMenuOpen: semobaeFileMenuOpen(state),
        semoclassParams: state.scratchGui.semobaeState.semoclassParams,
        codingTemplateUrl: state.scratchGui.semobaeState.codingTemplateUrl,
        vm: state.scratchGui.vm,
        isEditMenuOpen: editMenuOpen(state),
        isToolboxEditable: isToolboxEditable(toolboxState),
        languageMenuOpen: languageMenuOpen(state),
        isMissionMode: state.scratchGui.mode.isMissionMode,
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickFile: () => {
        dispatch(closeEditMenu());
        dispatch(openSemobaeFileMenu());
    },
    onRequestCloseFile: () => dispatch(closeSemobaeFileMenu()),
    onMouseUpLanguage: () => {
        dispatch(closeEditMenu());
        dispatch(closeSemobaeFileMenu());
        dispatch(openLanguageMenu())
    },
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onOpenEdit: () => {
        dispatch(closeSemobaeFileMenu());
        dispatch(openEditMenu());
    },
    onCloseEdit: () => dispatch(closeEditMenu()),
    onStartEditToolbox: () => dispatch(startEditToolbox()),
    onStopEditToolbox: () => dispatch(stopEditToolbox()),
    onOpenMalrangTutorialModal: () => dispatch(openMalrangTutorialModal()),
    closeSuccessDialog: () => dispatch(closeSuccessDialog()),
    closeFailDialog: () => dispatch(closeFailDialog()),
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SemobaeMenubarComponent);
